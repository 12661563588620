import api from "@/lib/api";
import i18n from '../../i18n'
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    template: JSON.parse(localStorage.getItem('sgara-comparison')) || [],
  },
  getters: {
    typeOptions() {
      return [
        {
          type: 1,
          label: i18n.t('warehouse.daily'),
          field: 'qty',
        },
        {
          type: 9,
          label: i18n.t('warehouse.probiotics') + '/' + i18n.t('warehouse.chemical'),
          field: 'qty',
        },
        {
          type: 2,
          label: 'pH',
          field: 'ph',
        },
        {
          type: 2,
          label: 'DO',
          field: 'DO',
        },
        {
          type: 2,
          label: i18n.t('wq.temperature'),
          field: 'temperature',
        },
        {
          type: 2,
          label: i18n.t('wq.turbidity'),
          field: 'turbidity',
        },
        {
          type: 2,
          label: i18n.t('wq.salinity'),
          field: 'salinity',
        },
        {
          type: 2,
          label: i18n.t('wq.wh'),
          field: 'water_height',
        },
        {
          type: 2,
          label: i18n.t('wq.oxygen'),
          field: 'oxygen',
        },
        {
          type: 2,
          label: 'ORP',
          field: 'orp',
        },
        {
          type: 3,
          label: 'TOM',
          field: 'tom',
        },
        {
          type: 3,
          label: 'Alkalinity',
          field: 'alkalinity',
        },
        {
          type: 3,
          label: 'PO4',
          field: 'po4',
        },
        {
          type: 3,
          label: 'NO3',
          field: 'no3',
        },
        {
          type: 3,
          label: 'NO2',
          field: 'no2',
        },
        {
          type: 3,
          label: 'NH4',
          field: 'nh4',
        },
        {
          type: 3,
          label: 'NH3',
          field: 'nh3',
        },
        {
          type: 3,
          label: 'TAN',
          field: 'tan',
        },
        {
          type: 3,
          label: 'CO3',
          field: 'co3',
        },
        {
          type: 3,
          label: 'HCO3',
          field: 'hco3',
        },
        {
          type: 3,
          label: 'Ca',
          field: 'ca',
        },
        {
          type: 3,
          label: 'Mg',
          field: 'mg',
        },
        {
          type: 3,
          label: 'Hardness',
          field: 'hard',
        },
        {
          type: 4,
          label: 'TBC',
          field: 'tbc',
        },
        {
          type: 4,
          label: 'TVC',
          field: 'tvc',
        },
        {
          type: 4,
          label: 'YVC',
          field: 'yvc',
        },
        {
          type: 4,
          label: 'GVC',
          field: 'gvc',
        },
        {
          type: 4,
          label: 'BVC',
          field: 'bvc',
        },
        {
          type: 4,
          label: 'LVC',
          field: 'lvc',
        },
        {
          type: 4,
          label: 'TVC/TBC(%)',
          field: 'tbc_tvc',
        },
        {
          type: 4,
          label: '%BGA',
          field: 'bga',
        },
        {
          type: 4,
          label: '%GA',
          field: 'ga',
        },
        {
          type: 4,
          label: '%GGA',
          field: 'gga',
        },
        {
          type: 4,
          label: '%DINO',
          field: 'dino',
        },
        {
          type: 4,
          label: '%PRO',
          field: 'pro',
        },
        {
          type: 4,
          label: '%EUG',
          field: 'eug',
        },
        {
          type: 4,
          label: 'Plankton',
          field: 'plankton',
        },
        {
          type: 5,
          label: i18n.t('warehouse.accumulative'),
          field: 'qty',
        },
        {
          type: 6,
          label: 'Feed Index',
          field: 'feed_index',
        },
        {
          type: 7,
          label: i18n.t('feed_tray.feed_tray'),
          field: 'data_anco',
        },
        {
          type: 8,
          label: 'MBW',
          field: 'mbw',
        },
        {
          type: 8,
          label: 'SR',
          field: 'sr',
        },
        {
          type: 8,
          label: 'Biomass',
          field: 'biomass',
        },
        {
          type: 8,
          label: 'FCR',
          field: 'fcr',
        },
      ];
    },
    template: (state, getters, rootState, rootGetters) => {
      return state.template.find(e => e.farm_id === rootGetters["farm/selectedFarm"].farm_id)
    }
  },
  mutations: {
    ADD_TEMPLATE(state, payload) {
      const {farm_id, data} = payload
      let find = state.template.find(e => e.farm_id === farm_id)
      if (find) find.data.push(data)
      else state.template.push({
        farm_id: farm_id,
        data: [data]
      })
      localStorage.setItem('sgara-comparison', JSON.stringify(state.template))
    },
    REMOVE_TEMPLATE(state, payload) {
      const {farm_id, index} = payload
      let find = state.template.find(e => e.farm_id === farm_id)
      if (find) find.data.splice(index, 1)
      localStorage.setItem('sgara-comparison', JSON.stringify(state.template))
    },
  },
  actions: {
    addTemplate({commit, rootGetters}, payload) {
      let farm_id = rootGetters["farm/selectedFarm"].farm_id
      commit('ADD_TEMPLATE', {
        farm_id,
        data: payload
      })
    },
    removeTemplate({commit, rootGetters}, payload) {
      let farm_id = rootGetters["farm/selectedFarm"].farm_id
      commit('REMOVE_TEMPLATE', {
        farm_id,
        index: payload
      })
    },
    async compare(context, payload) {
      let res = await api.get('/multipleComparison', payload)
      if (res && res.data && res.data.data1 && payload.type_1 === 1) {
        res.data.data1 = res.data.data1.reduce((a, b) => {
          let find = a.find(e => Vue.prototype.$isSameDay(e.created_at, b.created_at))
          if (find) find.qty += b.qty
          else a.push(b)
          return a
        }, [])
      }
      return res
    },
  },
}

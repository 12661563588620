import api from "@/lib/api";
import router from "@/router/router";
import {CometChat} from "@cometchat-pro/chat";

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('sgara.pgsql.user')),
    unreadMessagesCount: 0,
    cometChatLoggedIn: false,
    cometChatUser: null,
  },
  mutations: {
    UPDATE_USER(state, payload = null) {
      state.user = payload

      if (payload) {
        localStorage.setItem('sgara.pgsql.user', JSON.stringify(payload))
      } else {
        localStorage.removeItem('sgara.pgsql.user')
      }
    },
    UPDATE_UNREAD_MESSAGES_COUNT(state, payload) {
      let unreadMessagesCount = 0
      if (payload.users && payload.groups) {
        for (let k in payload.users) unreadMessagesCount += payload.users[k]
        for (let k in payload.groups) unreadMessagesCount += payload.groups[k]
      }
      state.unreadMessagesCount = unreadMessagesCount
    },
    UPDATE_COMET_CHAT_STATUS (state, payload= false) {
      state.cometChatLoggedIn = payload
    },
    UPDATE_COMET_CHAT_USER (state, payload= null) {
      state.cometChatUser = payload
    },
  },
  actions: {
    register(context, payload) {
      return api.post('/registrasi', payload)
    },
    loginEmail({commit}, payload) {
      return api.post('/login/email', payload).then(res => {
        if (res.data && res.data.token) commit("UPDATE_USER", res.data)
        return res
      }).catch(err => {
        return err
      })
    },
    loginPhone({commit}, payload) {
      return api.post('/login/phone', payload).then(res => {
        // if (res.data && res.data.token) commit("UPDATE_USER", res.data)
        return res
      }).catch(err => {
        return err
      })
    },
    loginOtp({commit}, payload) {
      return api.post('/login/otp', payload).then(res => {
        if (res.data && res.data.token) commit("UPDATE_USER", res.data)
        return res
      }).catch(err => {
        return err
      })
    },
    forgotPassword(context, payload) {
      return api.post('/forgetPassword', payload)
    },
    resetPassword(context, {signature, password, password_confirmation}) {
      return api.post('/reset/password?signature=' + signature, {
        password,
        password_confirmation
      })
    },
    logout({commit}) {
      localStorage.removeItem('sgara.pgsql.user')
      commit('UPDATE_USER')
      router.push('/login')
    },
    updateProfile({state, commit}, {username, phone, city, photo}) {
      return api.put('/user', {
        username,
        phone,
        city,
        photo,
      }, false).then(() => {
        state.user.data.username = username
        state.user.data.phone = phone
        state.user.data.city = city
        state.user.data.photo = photo
        commit("UPDATE_USER", state.user)
      })
    },
    updatePassword({state, commit}, {old_password, new_password, confirm_password,}) {
      return api.post('/user/password', {
        old_password,
        new_password,
        confirm_password,
      }, false)
    },
    emailVerification(context, payload) {
      return new Promise((resolve, reject) => {
        api.post('/email-verification', {
          signature: payload
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getUnreadMessagesCount ({commit}) {
      return new Promise((resolve, reject) => {
        CometChat.getUnreadMessageCount().then(res => {
          commit("UPDATE_UNREAD_MESSAGES_COUNT", res)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {
    loggedIn: state => state.user ? true : false,
    token: state => state.user ? state.user.token : null,
    user: state => state.user ? state.user.data : {},
  }
}

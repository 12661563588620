import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from "@/store/modules/user";
import farm from "@/store/modules/farm";
import setting from "@/store/modules/setting";
import comparison from "@/store/modules/comparison";
import finance from "@/store/modules/finance";

export default new Vuex.Store({
  modules: {
    user,
    farm,
    setting,
    comparison,
    finance,
  },
  state: {
    loading: false,
    sidebar: false,
    delete_dialog: false,
    delete_opt: {},
    update_navbar: false,

    navbarMargin: 0,
  },
  getters: {
    isLocal: () => {
      return Boolean(process.env.VUE_APP_SGARA_LOCAL) === true
    }
  },
  mutations: {
    UPDATE_NAVBAR(state, payload = true) {
      state.update_navbar = payload
    },
    UPDATE_NAVBAR_MARGIN(state, payload = 0) {
      state.navbarMargin = payload
    },
    SET_LOADING(state, payload = true) {
      state.loading = payload
    },
    SET_SIDEBAR(state, payload = true) {
      state.sidebar = payload
    },
    SET_DELETE_DIALOG(state, payload = true) {
      state.delete_dialog = payload
    },
    SET_DELETE_OPT(state, payload = null) {
      state.delete_opt = payload
    },
  },
  actions: {
    loading({commit}, payload = true) {
      commit('SET_LOADING', payload)
    },
    sidebar({commit}, payload = true) {
      commit('SET_SIDEBAR', payload)
    },
    deleteDialog({commit}, payload = true) {
      commit('SET_DELETE_DIALOG', payload)
    },
    deleteOpt({commit}, payload = null) {
      commit('SET_DELETE_OPT', payload)
    },
  },
})

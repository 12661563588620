<template>
  <div></div>
</template>

<script>
import {mapActions} from "vuex";
import {CometChat} from "@cometchat-pro/chat";

export default {
  name: "SyncChatUser",
  created() {
    this.sync()
  },
  methods: {
    ...mapActions('farm', [
      'getInternalFarm',
      'getUser',
    ]),

    async sync() {
      let farms = []

      let farm_name = ['Bahtera Hasil Limpah', 'Sgara Paloma', 'Arrival Farm']

      await this.getInternalFarm().then(res => {
        farms = res.data
      })

      farms = farms.filter(e => farm_name.includes(e.name))

      for (let i = 0; i < farms.length; i++) {
        let farm = farms[i]
        await this.chatCreateGroup(farm)
        let users = []
        await this.getUser(farm.farm_id).then(res => {
          users = res.data
        })
        await this.chatCreateUser(users)
        await this.chatGroupInviteUsers(farm, users)
      }
    },

    async chatCreateUser(users) {
      for (let i = 0; i < users.length; i++) {
        let payload = users[i]
        let uid = payload.pu_id;
        let name = payload.username;

        let user = new CometChat.User(uid);

        user.setName(name);

        await CometChat.createUser(user, process.env.VUE_APP_COMETCHAT_APP_AUTH_KEY)
            .catch(err => {
              console.log(err)
            })
      }
    },
    chatCreateGroup(farm) {
      let GUID = "sgara" + farm.farm_id;
      let groupName = farm.name;
      let groupType = CometChat.GROUP_TYPE.PRIVATE;

      let group = new CometChat.Group(GUID, groupName, groupType);

      CometChat.createGroup(group).then(
          group => {
            console.log("Group created successfully:", group);
          }, error => {
            console.log("Group creation failed with exception:", error);
          }
      );
    },
    chatGroupInviteUsers(farm, users) {
      let GUID = "sgara" + farm.farm_id;
      let membersList = users.filter(e => e.user_id !== 53).map(e => {
        return new CometChat.GroupMember(e.pu_id, e.role_id === 1 ? CometChat.GROUP_MEMBER_SCOPE.ADMIN : CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)
      })

      CometChat.addMembersToGroup(GUID, membersList, []).then(
          response => {
            console.log("response", response);
          }, error => {
            console.log("Something went wrong", error);
          }
      );
    },
  },
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://65da267198b44b588b44778c07c7f9d8@o1126888.ingest.sentry.io/6189612",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["sgara.id", "book.sgara.id", "sgara.local", "dev-book-sgara.local", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  })
}

import router from './router/router'
import store from './store/store'

import Buefy from 'buefy'

import '@/assets/style.scss'

Vue.use(Buefy)

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

import VueCurrencyInput from 'vue-currency-input'

const pluginOptions = {
  globalOptions: {currency: 'IDR'}
}
Vue.use(VueCurrencyInput, pluginOptions)

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// i18n Localization

import i18n from './i18n'

// Vee Validate

import {ValidationProvider, ValidationObserver} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import {extend} from 'vee-validate';
import {required, required_if, confirmed, numeric, double, max, email} from 'vee-validate/dist/rules';

let locale = i18n.locale

import en from 'vee-validate/dist/locale/en.json';
import id from 'vee-validate/dist/locale/id.json';

extend('required', {
  ...required,
  message: locale === 'en' ? en.messages['required'] : id.messages['required'],
});
extend('required_if', {
  ...required_if,
  message: locale === 'en' ? en.messages['required_if'] : id.messages['required_if'],
});
extend('email', {
  ...email,
  message: locale === 'en' ? en.messages['email'] : id.messages['email'],
});
extend('confirmed', {
  ...confirmed,
  message: locale === 'en' ? en.messages['confirmed'] : id.messages['confirmed'],
});
extend('numeric', {
  ...numeric,
  message: locale === 'en' ? en.messages['numeric'] : id.messages['numeric'],
});
extend('max', {
  ...max,
  message: locale === 'en' ? en.messages['max'] : id.messages['max'],
});
extend('double', {
  ...double,
  message: locale === 'en' ? en.messages['double'] : id.messages['double'],
});

// Loadash

import VueLodash from 'vue-lodash'
import cloneDeep from 'lodash/cloneDeep'

// name is optional
Vue.use(VueLodash, {lodash: {cloneDeep}})

// Moment

const moment = require('moment')
require('moment/locale/id')

Vue.use(require('vue-moment'), {
  moment
})

// Custom Helper

import lib from '@/lib/lib'

Vue.use(lib)

Vue.config.productionTip = false

import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)

import {CometChat} from "@cometchat-pro/chat";

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(process.env.VUE_APP_COMETCHAT_APP_REGION)
  .build();

CometChat.init(process.env.VUE_APP_COMETCHAT_APP_ID, appSetting).then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})

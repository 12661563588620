import Vue from 'vue'
import store from '../store/store'
import i18n from "@/i18n";


const loading = (value = true) => {
  store.dispatch('loading', value)
}

const timestamp = (value = null) => {
  let date = value ? Vue.prototype.$moment(new Date(value)) : Vue.prototype.$moment()
  return date.valueOf()
}

const dateFormat = (value = null, format = 'DD MMM YYYY') => {
  if (value) return Vue.prototype.$moment(new Date(value)).format(format)
  else return Vue.prototype.$moment().format(format)
}

const startDay = (value = null) => {
  if (value) return Vue.prototype.$moment(value).startOf('day')
  else return Vue.prototype.$moment().startOf('day')
}

const addMinute = (value, minutes) => {
  return Vue.prototype.$moment(value).add(minutes, 'minutes')
}

const endDay = (value = null) => {
  if (value) return Vue.prototype.$moment(value).endOf('day')
  else return Vue.prototype.$moment().endOf('day')
}

const isSameDay = (a = null, b = null) => {
  a = startDay(a).valueOf()
  b = startDay(b).valueOf()

  return a === b
}

const timeFormat = (value = null, format = 'HH:mm') => {
  if (value) return Vue.prototype.$moment(new Date(value)).format(format)
  else return Vue.prototype.$moment().format(format)
}

const currency = (value = 0) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0
  })

  let result = formatter.format(value)

  return result.replace('IDR', 'Rp')
}

let fnDeleteDialog = null

const deleteDialog = async (fn = null, is_submit = false, opt = {}) => {
  if (fn) fnDeleteDialog = fn
  store.dispatch('deleteDialog', !is_submit)
  store.dispatch('deleteOpt', opt)

  if (is_submit) {
    if (typeof fnDeleteDialog === 'function') await fnDeleteDialog()
    fnDeleteDialog = null
  }
}

const getDOC = (date1 = null, date2 = null) => {
  let from = date2 ? Vue.prototype.$moment(new Date(Vue.prototype.$moment(date2))) : Vue.prototype.$moment()
  return from.diff(Vue.prototype.$moment(new Date(date1)), 'days') + 1
}

const base64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file && file.name && typeof file.name === 'string') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    } else resolve(file)
  });
}

const dataURLtoFile = (dataUrl, filename) => {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bStr = atob(arr[1]),
    n = bStr.length,
    u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bStr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type:mime});
}

const getColor = () => {
  return [
    {
      "label_en": "Brown",
      "label_id": "Coklat",
      "value": 1
    },
    {
      "label_en": "Light Brown",
      "label_id": "Coklat Muda",
      "value": 2
    },
    {
      "label_en": "Green Brown",
      "label_id": "Coklat Hijau",
      "value": 3
    },
    {
      "label_en": "Yellow Brown",
      "label_id": "Coklat Kuning",
      "value": 4
    },
    {
      "label_en": "Red Brown",
      "label_id": "Coklat Merah",
      "value": 5
    },
    {
      "label_en": "Milk Brown",
      "label_id": "Coklat Susu",
      "value": 6
    },
    {
      "label_en": "Dark Brown",
      "label_id": "Coklat Gelap",
      "value": 7
    },
    {
      "label_en": "Green",
      "label_id": "Hijau",
      "value": 8
    },
    {
      "label_en": "Blue Green",
      "label_id": "Hijau Biru",
      "value": 9
    },
    {
      "label_en": "Brown Green",
      "label_id": "Hijau Coklat",
      "value": 17
    },
    {
      "label_en": "Dark Green",
      "label_id": "Hijau Gelap",
      "value": 10
    },
    {
      "label_en": "Light Green",
      "label_id": "Hijau Muda",
      "value": 11
    },
    {
      "label_en": "Red",
      "label_id": "Merah",
      "value": 12
    },
    {
      "label_en": "Pink",
      "label_id": "Merah Muda",
      "value": 13
    },
    {
      "label_en": "Black",
      "label_id": "Kehitaman",
      "value": 14
    },
    {
      "label_en": "Luminous",
      "label_id": "Menyala",
      "value": 15
    },
    {
      "label_en": "Shiny",
      "label_id": "Mengkilat",
      "value": 16
    }
  ]
}

const getWeather = () => {
  return [
    {
      "label_en": "Heavy Rain",
      "label_id": "Hujan Lebat",
      "value": 1
    },
    {
      "label_en": "Rain",
      "label_id": "Hujan",
      "value": 2
    },
    {
      "label_en": "Drizzle",
      "label_id": "Gerimis",
      "value": 3
    },
    {
      "label_en": "Cloudy",
      "label_id": "Berawan",
      "value": 4
    },
    {
      "label_en": "Cloudy Sunny",
      "label_id": "Cerah Berawan",
      "value": 5
    },
    {
      "label_en": "Sunnny",
      "label_id": "Cerah",
      "value": 6
    },
    {
      "label_en": "Hot",
      "label_id": "Panas Terik",
      "value": 7
    }
  ]
}

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
}

const lang = () => {
  return i18n.locale
}

const labelLang = () => {
  return i18n.locale === 'en' ? 'label_en' : 'label_id'
}

const getColorById = (id) => {
  return getColor().find(e => e.value === id)
}

const getColorLabel = (id) => {
  let color = getColorById(id)
  return color ? color[labelLang()] : ''
}

const getWeatherById = (id) => {
  return getWeather().find(e => e.value === id)
}

const getWeatherLabel = (id) => {
  let weather = getWeatherById(id)
  return weather ? weather[labelLang()] : ''
}

const number = (n = 0, decimal = 2) => {
  return +parseFloat(n).toFixed(decimal)
}

const numberFormat = (value = 0, decimal = 0) => {
  if (decimal) value = number(value, decimal)

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0
  })

  return formatter.format(value)
}

const customNumberFormat = (value = 0) => {
  return numberFormat(number(value))
}

const exponential = (value = 0) => {
  if (value) return parseFloat(value).toExponential().replace('e', ' x 10^').replace('+', '')
  return null
}

const plugin = {
  install() {
    Vue.prototype.$loading = loading
    Vue.prototype.$timestamp = timestamp
    Vue.prototype.$currency = currency
    Vue.prototype.$delete = deleteDialog
    Vue.prototype.$dateFormat = dateFormat
    Vue.prototype.$startDay = startDay
    Vue.prototype.$endDay = endDay
    Vue.prototype.$isSameDay = isSameDay
    Vue.prototype.$timeFormat = timeFormat
    Vue.prototype.$getDOC = getDOC
    Vue.prototype.$getColor = getColor()
    Vue.prototype.$getColorLabel = getColorLabel
    Vue.prototype.$getColorById = getColorById
    Vue.prototype.$getWeather = getWeather()
    Vue.prototype.$getWeatherLabel = getWeatherLabel
    Vue.prototype.$getWeatherById = getWeatherById
    Vue.prototype.$lang = lang()
    Vue.prototype.$labelLang = labelLang()
    Vue.prototype.$unique = unique
    Vue.prototype.$base64 = base64
    Vue.prototype.$dataURLtoFile = dataURLtoFile
    Vue.prototype.$number = number
    Vue.prototype.$numberFormat = numberFormat
    Vue.prototype.$customNumberFormat = customNumberFormat
    Vue.prototype.$exponential = exponential
    Vue.prototype.$addMinute = addMinute
  }
}

export default plugin

<template>
  <div id="app">
    <b-loading :is-full-page="true" v-model="loading" :can-cancel="true"></b-loading>
    <router-view/>
    <sg-delete-dialog v-model="deleteDialog" :title="deleteMessage" @submit="handleSubmit"/>

    <audio :src="incomingOtherMessageAlert" ref="audio"/>

    <b-button type="is-primary"
              tag="router-link" to="/chat"
              class="chat-btn has-text-weight-bold hide-print"
              :class="{'is-report': isReport}"
              v-if="!hideChat && cometChatLoggedIn" rounded>
      <b-icon icon="chat-processing-outline" size="is-medium"/>
      <span class="ml-3">Chat</span>
      <span class="unread-message-count" v-if="unreadMessagesCount">{{ unreadMessagesCount }}</span>
    </b-button>
    <b-button type="is-primary"
              tag="a" href="https://wa.me/6287878669998?text=Halo, saya butuh bantuan untuk tambak saya di Sgara"
              icon-left="help-circle"
              class="help-btn has-text-weight-bold"
              :class="{'is-report': isReport}"
              v-if="hideChat && !hideHelp"
              target="_blank" rounded>
      <span class="is-hidden-mobile">{{ $t('help') }}</span>
    </b-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {CometChat} from "@cometchat-pro/chat";
import {firebaseInitialize, firebaseMessage} from './firebase'
import {incomingOtherMessageAlert} from "@/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/audio";
import {
  ConversationListManager,
} from "@/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/components/Chats/CometChatConversationList/controller";
import {CometChatManager} from "@/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/util/controller";
import {CometChatEvent} from "@/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/util/CometChatEvent";
import * as enums from "@/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/util/enums";
import {COMETCHAT_CONSTANTS} from "@/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/constants";
import {
  cometChatCommon,
  cometChatScreens,
  propertyCheck
} from "@/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/mixins";

let conversationListManager;

export default {
  components: {SgDeleteDialog: () => import("@/components/Sg/SgDeleteDialog")},
  mixins: [propertyCheck, cometChatCommon, cometChatScreens],
  data: () => {
    return {
      audio: null,
      type: "",
      conversationList: [],
      selectedConversation: null,
      decoratorMessage: COMETCHAT_CONSTANTS.LOADING_MESSSAGE,
      showConfirmDialog: false,
      conversationToBeDeleted: null,
    }
  },
  computed: {
    incomingOtherMessageAlert() {
      return incomingOtherMessageAlert
    },

    ...mapGetters('user', [
      'loggedIn',
      'user',
    ]),

    ...mapState('user', [
      'unreadMessagesCount',
      'cometChatLoggedIn',
    ]),

    loading: {
      get() {
        return this.$store.state.loading
      },
      set() {
      },
    },
    deleteDialog: {
      get() {
        return this.$store.state.delete_dialog
      },
      set(value) {
        this.$store.dispatch('deleteDialog', value)
      },
    },
    deleteOpt() {
      return this.$store.state.delete_opt
    },
    deleteMessage() {
      return this.deleteOpt.message ? this.deleteOpt.message : this.$t('delete_desc')
    },
    isReport() {
      return this.$route.name === 'Report'
    },
    hideHelp() {
      return this.$route.meta.hideHelp === true
    },
    hideChat() {
      return this.$route.meta.hideChat === true
    },
  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.initChat()
      } else {
        this.destroyCometChatService()
      }
    }
  },
  async created() {
    this.init()
    this.initChat()
  },
  beforeMount() {
    if (this.loggedIn) this.initCometChatService()
  },
  beforeDestroy() {
    this.destroyCometChatService()
  },
  beforeUnmount() {
    this.destroyCometChatService()
  },
  methods: {
    ...mapActions('user', [
      'getUnreadMessagesCount',
    ]),

    handleSubmit() {
      this.$delete(null, true)
    },
    init() {
      let time = this.$store.state.setting.time

      if (!time) {
        this.$store.commit('setting/UPDATE_SETTING', {
          time: ['06:00', '14:00', '22:00']
        })
      }
    },
    initChat() {
      this.chatLogin()
    },

    async initFirebase() {
      await firebaseInitialize()
      await firebaseMessage()
    },

    initCometChatService() {
      this.audio = new Audio(incomingOtherMessageAlert);

      this.createManager();

      this.getConversations();
      this.attachListeners();

      this.cometChatEventListeners();
    },

    destroyCometChatService() {
      this.removeListeners();
      this.cometChatRemoveEventListeners();
    },

    chatLogin() {
      this.$store.commit('user/UPDATE_COMET_CHAT_STATUS')
      this.$store.commit('user/UPDATE_COMET_CHAT_USER')
      CometChat.login(this.user.pu_id, process.env.VUE_APP_COMETCHAT_APP_AUTH_KEY)
          .then(async res => {
            this.$store.commit('user/UPDATE_COMET_CHAT_STATUS', true)
            this.$store.commit('user/UPDATE_COMET_CHAT_USER', res)
            await this.initFirebase()
            await this.initCometChatService()
            await this.getUnread()
          })
          .catch(err => {
            // if (err.code === "ERR_UID_NOT_FOUND") this.chatCreateUser(this.user)
          })
    },
    chatCreateUser(payload) {
      let uid = payload.pu_id;
      let name = payload.username;

      let user = new CometChat.User(uid);

      user.setName(name);

      CometChat.createUser(user, process.env.VUE_APP_COMETCHAT_APP_AUTH_KEY)
          .then(() => {
            this.chatLogin()
          })
          .catch(err => {
            console.log(err)
          })
    },
    getUnread() {
      this.getUnreadMessagesCount()
    },

    createManager() {
      conversationListManager = new ConversationListManager();
    },
    attachListeners() {
      if (conversationListManager) {
        conversationListManager.attachListeners(
            this.conversationsUpdateHandler
        );
      }
    },
    removeListeners() {
      if (conversationListManager) {
        conversationListManager.removeListeners();
        conversationListManager = null;
      }
    },
    cometChatEventListeners() {
      /** updating last message whenever a message is composed and sent */
      CometChatEvent.on(enums.EVENTS["UPDATED_LAST_MESSAGES"], args => this.updateLastMessage(args));

      /**Listen for the new messages if user is already in the chat window and has scrolled up*/
      CometChatEvent.on(enums.EVENTS["NEW_MESSAGES_TRIGGERED"], args => this.updateUnreadCount(args));

      /** clearing unreadcount whenever scrolled to the bottom.*/
      CometChatEvent.on(enums.EVENTS["CLEAR_UNREAD_MESSAGES_TRIGGERED"], args => this.clearUnreadCount(args));

      /**Delete conversation */
      CometChatEvent.on(enums.EVENTS["DELETE_CONVERSATION"], args => {
        this.deleteConversation(args)
      })

      /**Confirm delete */
      CometChatEvent.on(enums.EVENTS["CONFIRM_RESPONSE"], (e) => {
        this.onDeleteConfirm(e)
      })
    },
    cometChatRemoveEventListeners() {
      CometChatEvent.remove(enums.EVENTS["UPDATED_LAST_MESSAGES"]);
      CometChatEvent.remove(enums.EVENTS["NEW_MESSAGES_TRIGGERED"]);
      CometChatEvent.remove(enums.EVENTS["CLEAR_UNREAD_MESSAGES_TRIGGERED"]);
      CometChatEvent.remove(enums.EVENTS["DELETE_CONVERSATION"])
      CometChatEvent.remove(enums.EVENTS["CONFIRM_RESPONSE"])
    },
    conversationsUpdateHandler(key, item, message, options) {
      this.logInfo("CometChatConversationList: conversationsUpdateHandler", {
        key,
        item,
        message,
        options,
      });
      switch (key) {
        case enums.USER_ONLINE:
        case enums.USER_OFFLINE:
          this.updateUser(item);
          break;
        case enums.TEXT_MESSAGE_RECEIVED:
        case enums.MEDIA_MESSAGE_RECEIVED:
        case enums.CUSTOM_MESSAGE_RECEIVED:
          this.markMessageAsDelivered(message);
          this.updateConversation(message);
          this.getUnreadMessagesCount()
          break;
        case enums.MESSAGE_EDITED:
        case enums.MESSAGE_DELETED:
          this.conversationEditedDeleted(message);
          break;
        case enums.INCOMING_CALL_RECEIVED:
        case enums.INCOMING_CALL_CANCELLED:
          this.updateConversation(message, false);
          break;
        case enums.GROUP_MEMBER_ADDED:
          this.updateGroupMemberAdded(message, options);
          break;
        case enums.GROUP_MEMBER_KICKED:
        case enums.GROUP_MEMBER_LEFT:
          this.updateGroupMemberRemoved(message, options);
          break;
        case enums.GROUP_MEMBER_SCOPE_CHANGED:
          this.updateGroupMemberScopeChanged(message, options);
          break;
        case enums.GROUP_MEMBER_JOINED:
          this.updateGroupMemberChanged(message, options, "increment");
          break;
        default:
          break;
      }
    },
    /**
     * Update last message
     */
    updateLastMessage(lastMessage) {
      const conversationList = [...this.conversationList];
      const conversationKey = conversationList.findIndex(c => c.conversationId === lastMessage.conversationId);

      if (conversationKey > -1) {
        const conversationObj = conversationList[conversationKey];
        let newConversationObj = {...conversationObj, lastMessage: {...lastMessage}};

        if (conversationKey === 0) {
          conversationList.splice(conversationKey, 1, newConversationObj);
        } else {
          conversationList.splice(conversationKey, 1);
          conversationList.unshift(newConversationObj);
        }

        this.conversationList = conversationList;
      }
    },
    /**
     * Uupdate unread count
     */
    updateUnreadCount(params) {
      if (this.selectedConversation) {
        this.selectedConversation["unreadMessages"] = params.unreadMessages;
        return false;
      }
    },
    /**
     * clear unread count
     */
    clearUnreadCount() {
      if (this.selectedConversation) {

        this.selectedConversation["unreadMessages"] = [];
        let conversationList = [...this.conversationList];

        let conversationKey = conversationList.findIndex(c => c.conversationId === this.selectedConversation.conversationId);

        if (conversationKey > -1) {
          let conversationObj = {...conversationList[conversationKey]};
          let newConversationObj = {...conversationObj, unreadMessageCount: 0};

          conversationList.splice(conversationKey, 1);
          conversationList.unshift(newConversationObj);
          this.conversationList = conversationList
        }
      }
    },


    async getConversations(clear = false) {
      try {
        const user = await new CometChatManager().getLoggedInUser();
        this.loggedInUser = user;

        if (!conversationListManager) {
          this.createManager();
          this.attachListeners();
        }

        const conversations = await conversationListManager.fetchNextConversation();

        if (conversations.length === 0) {
          this.decoratorMessage = COMETCHAT_CONSTANTS.NO_CHATS_FOUND;
        }

        conversations.forEach((conversation) => {
          if (
              this.type &&
              this.item &&
              this.type === conversation.conversationType
          ) {
            if (
                (conversation.conversationType === "user" &&
                    this.item.uid === conversation.conversationWith.uid) ||
                (conversation.conversationType === "group" &&
                    this.item.guid === conversation.conversationWith.guid)
            ) {
              conversation.unreadMessageCount = 0;
            }
          }
        });

        if (clear) {
          this.conversationList = conversations;
        } else {
          this.conversationList = [...this.conversationList, ...conversations];
        }
      } catch (error) {
        this.decoratorMessage = COMETCHAT_CONSTANTS.ERROR_LOADING_CHATS;
        this.logError(
            "[CometChatConversationList] getConversations error",
            error
        );
      }
    },
    async updateConversation(message, notification = true) {
      try {
        const {
          conversations,
          conversationKey,
          conversationObj,
        } = await this.makeConversation(message);

        if (conversationKey > -1) {
          let unreadMessageCount = this.makeUnreadMessageCount(conversationObj);
          let lastMessageObj = this.makeLastMessage(message, conversationObj);

          let newConversationObj = {
            ...conversationObj,
            lastMessage: lastMessageObj,
            unreadMessageCount: unreadMessageCount,
          };
          conversations.splice(conversationKey, 1);
          conversations.unshift(newConversationObj);
        } else {
          let unreadMessageCount = this.makeUnreadMessageCount();
          let lastMessageObj = this.makeLastMessage(message);

          let newConversationObj = {
            ...conversationObj,
            lastMessage: lastMessageObj,
            unreadMessageCount: unreadMessageCount,
          };
          conversations.unshift(newConversationObj);
        }

        this.conversationList = conversations;

        if (notification) {
          this.playAudio(message);
        }
      } catch (error) {
        this.logError("Error in converting message to conversation", error);
      }
    },
    markMessageAsDelivered(message) {
      //if chat window is not open, mark message as delivered
      if ((this.type === "" || Object.keys(this.item).length === 0)
          && !message.getDeliveredAt()) {
        CometChat.markAsDelivered(message);
      }
    },
    /**
     * Creates a message for conversation
     */
    makeConversation(message) {
      return new Promise((resolve, reject) => {
        CometChat.CometChatHelper.getConversationFromMessage(message)
            .then((conversation) => {
              let conversations = [...this.conversationList];

              let conversationKey = conversations.findIndex(
                  (c) => c.conversationId === conversation.conversationId
              );

              let conversationObj = {...conversation};
              if (conversationKey > -1) {
                conversationObj = {...conversations[conversationKey]};
              }

              resolve({
                conversationKey: conversationKey,
                conversationObj: conversationObj,
                conversations: conversations,
              });
            })
            .catch((error) => reject(error));
      });
    },
    /**
     * Makes unread message count
     */
    makeUnreadMessageCount(conversation = {}, operator) {
      try {
        if (Object.keys(conversation).length === 0) {
          return 1;
        }

        let unreadMessageCount = parseInt(conversation.unreadMessageCount);

        if (
            this.selectedConversation &&
            this.selectedConversation.conversationId ===
            conversation.conversationId
        ) {
          if (this.selectedConversation.unreadMessages && this.selectedConversation.unreadMessages.length) {
            const firstUnreadMessage = this.selectedConversation.unreadMessages[0];
            const selectedConversation = this.selectedConversation;

            if (firstUnreadMessage.conversationId && firstUnreadMessage.conversationId === selectedConversation.conversationId) {
              unreadMessageCount = 0;
              this.selectedConversation.unreadMessages.forEach(message => {
                unreadMessageCount = this.shouldIncrementCount(message) ? ++unreadMessageCount : unreadMessageCount;
              });
            }
          } else {
            unreadMessageCount = 0;
          }
        } else if (
            (this.item &&
                this.hasProperty(this.item, "guid") &&
                this.hasProperty(conversation.conversationWith, "guid") &&
                this.item.guid === conversation.conversationWith.guid) ||
            (this.item &&
                this.hasProperty(this.item, "uid") &&
                this.hasProperty(conversation.conversationWith, "uid") &&
                this.item.uid === conversation.conversationWith.uid)
        ) {
          unreadMessageCount = 0;
        } else {
          if (operator && operator === "decrement") {
            unreadMessageCount = unreadMessageCount
                ? unreadMessageCount - 1
                : 0;
          } else {
            unreadMessageCount = unreadMessageCount + 1;
          }
        }

        return unreadMessageCount;
      } catch (error) {
        this.logError(
            "[CometChatConversationList] unreadMessageCount error",
            error
        );
      }
    },
    /**
     *
     */
    shouldIncrementCount(incomingMessage) {
      let output = false;
      if (
          incomingMessage.sender.uid !== this.loggedInUser?.uid
      ) {
        output = true;
      }

      return output;
    },
    /**
     * Makes last message
     */
    makeLastMessage(message) {
      return Object.assign({}, message);
    },

    /**
     * Plays incoming message alert
     */
    playAudio(message) {
      try {
        if (
            message.category === enums.CATEGORY_ACTION &&
            message.type === enums.ACTION_TYPE_GROUPMEMBER
        ) {
          return false;
        }

        this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
      } catch (error) {
        this.logError("Error playing audio", error);
      }
    },

  }
}
</script>

import { render, staticRenderFns } from "./CometChatSenderImageMessageBubble.vue?vue&type=template&id=025f8c63&scoped=true&"
import script from "./CometChatSenderImageMessageBubble.vue?vue&type=script&lang=js&"
export * from "./CometChatSenderImageMessageBubble.vue?vue&type=script&lang=js&"
import style0 from "./CometChatSenderImageMessageBubble.vue?vue&type=style&index=0&id=025f8c63&scoped=true&lang=css&"
import style1 from "./CometChatSenderImageMessageBubble.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "025f8c63",
  null
  
)

export default component.exports
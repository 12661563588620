import api from "@/lib/api";

export default {
  namespaced: true,
  state: {
    account: [],
    selectedAccount: {
      account_id: null,
    },
    totalTransaction: null,
    cashFlow: null,
    arusKas: null,
    groupTransaction: {
      in: null,
      out: null,
    },
    hpp: null,
    produktivitas: null,

    transaction: [],
    categories: {
      in: [],
      out: [],
    },
    custom_categories: {
      in: [],
      out: [],
    },
    selectedCategory: {},
    selectedTransaction: {
      transaction_record_id: null,
    }
  },
  getters: {
    account: state => state.account,
    selectedAccount: state => state.selectedAccount,
    incomeTransaction: state => state.groupTransaction.in,
    expensesTransaction: state => state.groupTransaction.out,

    incomeCategories: state => state.categories.in,
    expensesCategories: state => state.categories.out,
    customIncomeCategories: state => state.custom_categories.in,
    customExpensesCategories: state => state.custom_categories.out,
    allIncomeCategories: state => [
      ...state.custom_categories.in,
      ...state.categories.in,
    ],
    allExpensesCategories: state => [
      ...state.custom_categories.out,
      ...state.categories.out,
    ],
    allCategories: state => [
      ...state.custom_categories.in,
      ...state.categories.in,
      ...state.custom_categories.out,
      ...state.categories.out,
    ],
    isClosed: state => state.selectedAccount ? state.selectedAccount.end_date !== 0 : false,
    isOpen: state => state.selectedAccount ? state.selectedAccount.end_date === 0 : false,
  },
  mutations: {
    UPDATE_ACCOUNT(state, payload = []) {
      state.account = payload
    },
    UPDATE_SELECTED_ACCOUNT(state, payload = {}) {
      state.selectedAccount = {
        ...payload
      }
    },
    UPDATE_TOTAL_TRANSACTION(state, payload = null) {
      state.totalTransaction = payload
    },
    UPDATE_CASH_FLOW(state, payload = null) {
      state.cashFlow = payload
    },
    UPDATE_ARUS_KAS(state, payload = null) {
      state.arusKas = payload
    },
    UPDATE_HPP(state, payload = null) {
      state.hpp = payload
    },
    UPDATE_PRODUCTIVITAS(state, payload = null) {
      state.produktivitas = payload
    },
    UPDATE_GROUP_TRANSACTION(state, {type, data = null}) {
      state.groupTransaction[type] = data
    },

    UPDATE_CATEGORY(state, {type = 'in', data = []}) {
      state.categories[type] = data
    },
    UPDATE_CUSTOM_CATEGORY(state, {type = 'in', data = []}) {
      state.custom_categories[type] = data
    },
    UPDATE_TRANSACTION(state, payload = []) {
      state.transaction = payload
    },
    UPDATE_SELECTED_TRANSACTION(state, payload = {}) {
      state.selectedTransaction = {
        ...payload
      }
    },
    UPDATE_SELECTED_CATEGORY(state, payload = {}) {
      state.selectedCategory = {
        ...payload
      }
    },
  },
  actions: {
    createAccount(context, {farm_id, name, created_at}) {
      return new Promise((resolve, reject) => {
        api.post('/account', {
          farm_id,
          name,
          created_at,
        }, false).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getAccount({commit, rootState}) {
      commit('UPDATE_ACCOUNT')
      commit('UPDATE_SELECTED_ACCOUNT')
      return new Promise((resolve, reject) => {
        api.get('/account', {
          farm_id: rootState.farm.selectedFarm.farm_id
        }).then(res => {
          if (Array.isArray(res.data) && res.data.length) {
            res.data = res.data.sort((a,b) => b.created_at > a.created_at ? 1 : -1)
            commit('UPDATE_SELECTED_ACCOUNT', res.data[0])
            commit('UPDATE_ACCOUNT', res.data)
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateAccount(context, payload) {
      return new Promise((resolve, reject) => {
        api.put('/account/' + payload.account_id + '/update', payload, false).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getAccountDetail({commit, rootState, state}) {
      commit('UPDATE_SELECTED_ACCOUNT', {
        account_id: state.selectedAccount.account_id
      })
      return new Promise((resolve, reject) => {
        api.get('/account', {
          farm_id: rootState.farm.selectedFarm.farm_id,
          account_id: state.selectedAccount.account_id,
        }).then(res => {
          if (res.data.account_id) commit('UPDATE_SELECTED_ACCOUNT', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteAccount(context, account_id) {
      return new Promise((resolve, reject) => {
        api.destroy('/account/' + account_id + '/delete').then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getTotalTransaction({state, commit}, payload = {}) {
      const {from = null, to = null} = payload
      commit('UPDATE_TOTAL_TRANSACTION')
      return new Promise((resolve, reject) => {
        api.get('/totalTransaction', {
          account_id: state.selectedAccount.account_id,
          from,
          to
        }).then(res => {
          if (res && res.data) commit('UPDATE_TOTAL_TRANSACTION', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getGroupTransaction({state, commit}, payload ={}) {
      const {type, from = null, to = null} = payload
      commit('UPDATE_GROUP_TRANSACTION', {
        type
      })
      return new Promise((resolve, reject) => {
        api.get('/groupTransaction', {
          account_id: state.selectedAccount.account_id,
          type,
          from,
          to,
        }).then(res => {
          if (res && Array.isArray(res.data)) commit('UPDATE_GROUP_TRANSACTION', {
            type,
            data: res.data
          })
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCashFlowPerMonth({state, commit}, payload = {}) {
      const {from = null, to = null} = payload
      commit('UPDATE_CASH_FLOW')
      return new Promise((resolve, reject) => {
        api.get('/chartCashFlowPerMonth', {
          account_id: state.selectedAccount.account_id,
          from,
          to,
        }).then(res => {
          if (res && res.data) commit('UPDATE_CASH_FLOW', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getArusKasByCategory({state, commit}, payload = {}) {
      const {from = null, to = null} = payload
      commit('UPDATE_ARUS_KAS')
      return new Promise((resolve, reject) => {
        api.get('/arusKasPercategoryFilterByMonth', {
          account_id: state.selectedAccount.account_id,
          from,
          to,
        }).then(res => {
          if (res && res.data) commit('UPDATE_ARUS_KAS', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getHpp({state, commit}, payload = {}) {
      const {from = null, to = null} = payload
      commit('UPDATE_HPP')
      return new Promise((resolve, reject) => {
        api.get('/analysHpp', {
          account_id: state.selectedAccount.account_id,
          from,
          to,
        }).then(res => {
          if (res && res.data) commit('UPDATE_HPP', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getProduktivitas({rootState, commit}, payload = {}) {
      const {from = null, to = null} = payload
      commit('UPDATE_PRODUCTIVITAS')
      return new Promise((resolve, reject) => {
        api.get('/getProductivitas', {
          farm_id: rootState.farm.selectedFarm.farm_id,
          from,
          to,
        }).then(res => {
          if (res && res.data) commit('UPDATE_PRODUCTIVITAS', res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },


    addTransaction({rootState}, {category_transaction_id, metode, nominal, transaction_date, note, photos = null}) {
      return new Promise((resolve, reject) => {
        api.post('/transaction', {
          account_id: rootState.finance.selectedAccount.account_id,
          category_transaction_id,
          metode,
          nominal,
          transaction_date,
          note,
          photos,
        }, false).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getTransaction({commit, rootState}) {
      commit('UPDATE_TRANSACTION')
      return new Promise((resolve, reject) => {
        api.get('/transaction', {
          account_id: rootState.finance.selectedAccount.account_id
        }).then(res => {
          if (Array.isArray(res.data)) {
            commit('UPDATE_TRANSACTION', res.data)
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateTransaction(context, payload) {
      return new Promise((resolve, reject) => {
        api.put('/transaction/' + payload.transaction_record_id + '/update', payload, false).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteTransaction(context, transaction_id) {
      return new Promise((resolve, reject) => {
        api.destroy('/transaction/' + transaction_id + '/delete').then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getTransactionCategory({commit, state}, type = 'in') {
      if (state.categories[type].length) return state.categories[type]

      commit('UPDATE_CATEGORY', {type})
      return new Promise((resolve, reject) => {
        api.get('/account/category', {
          type,
          farm_id: 0,
        }).then(res => {
          if (Array.isArray(res.data)) {
            commit('UPDATE_CATEGORY', {
              type,
              data: res.data.filter(e => e.farm_id === 0)
            })
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCustomTransactionCategory({commit, rootState}, type = 'in') {
      let farm_id = rootState.farm.selectedFarm.farm_id

      commit('UPDATE_CUSTOM_CATEGORY', {type})

      if (farm_id) {
        return new Promise((resolve, reject) => {
          api.get('/account/category', {
            type,
            farm_id,
          }).then(res => {
            if (Array.isArray(res.data)) {
              commit('UPDATE_CUSTOM_CATEGORY', {
                type,
                data: res.data
              })
            }
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      }
    },
    addCustomTransactionCategory({rootState}, {type, category_icon = '', category_transaction_name}) {
      return new Promise((resolve, reject) => {
        api.post('/account/category', {
          farm_id: rootState.farm.selectedFarm.farm_id,
          type,
          category_icon,
          category_transaction_name,
        }, false).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateCustomTransactionCategory(context, payload) {
      return new Promise((resolve, reject) => {
        api.put('/account/category/' + payload.category_transaction_id + '/update', payload, false).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteCustomTransactionCategory({rootState}, category_transaction_id) {
      return new Promise((resolve, reject) => {
        api.post('/account/category/' + category_transaction_id + '/delete', {
          farm_id: rootState.farm.selectedFarm.farm_id
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}

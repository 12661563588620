<template>
  <div class="is-flex is-justify-content-space-between mb-2 pb-2 border-bottom">
    <div class="is-flex is-align-items-center">
      <div :style="styles.avatar" class="member__list__avatar">
        <comet-chat-avatar
            border-width="1px"
            corner-radius="18px"
            :image="member.avatar"
            :border-color="theme.borderColor.primary"
        />

        <comet-chat-user-presence
            border-width="1px"
            corner-radius="50%"
            :status="member.status"
            :border-color="theme.borderColor.primary"
        />
      </div>
      <div>
        <div :style="styles.name">{{ member.name }}</div>
        <span :style="styles.role">{{ roles[member.scope] }}</span>
      </div>
    </div>
    <div :style="{ ...styles.action }" v-if="canUnBan">
      <div class="is-flex is-align-items-center is-flex-direction-column">
        <img
            :src="unbanImage"
            :alt="STRINGS.UNBAN"
            :style="styles.action.img"
            @click="emitAction('unBan', { member })"
        />
        UNBAN
      </div>
    </div>
  </div>
</template>
<script>
import {CometChat} from "@cometchat-pro/chat";

import {
  COMETCHAT_CONSTANTS,
  DEFAULT_OBJECT_PROP,
} from "../../../resources/constants";

import {cometChatCommon, tooltip} from "../../../mixins/";

import {CometChatAvatar, CometChatUserPresence} from "../../Shared";

import * as style from "./style";

import unban from "./resources/block.png";

/**
 * List item for ban group member list.
 *
 * @displayName CometChatBanGroupMemberListItem
 */
export default {
  name: "CometChatBanGroupMemberListItem",
  mixins: [tooltip, cometChatCommon],
  components: {
    CometChatAvatar,
    CometChatUserPresence,
  },
  props: {
    /**
     * The selected chat item object.
     */
    item: {...DEFAULT_OBJECT_PROP},
    /**
     * Theme of the UI.
     */
    theme: {...DEFAULT_OBJECT_PROP},
    /**
     * Member object.
     */
    member: {...DEFAULT_OBJECT_PROP},
    /**
     * Current logged in user.
     */
    loggedInUser: {...DEFAULT_OBJECT_PROP},
  },
  computed: {
    /**
     * Computed styles for the component.
     */
    styles() {
      return {
        name: style.nameStyle(),
        role: style.roleStyle(),
        action: style.actionStyle(),
        avatar: style.avatarStyle(),
        tableRow: style.tableRowStyle(this.theme),
      };
    },
    /**
     * Local string constants.
     */
    STRINGS() {
      return COMETCHAT_CONSTANTS;
    },
    /**
     * Unban icon image.
     */
    unbanImage() {
      return unban;
    },
    /**
     * Computed member roles.
     */
    roles() {
      return {
        [CometChat.GROUP_MEMBER_SCOPE.ADMIN]: COMETCHAT_CONSTANTS.ADMINISTRATOR,
        [CometChat.GROUP_MEMBER_SCOPE.MODERATOR]: COMETCHAT_CONSTANTS.MODERATOR,
        [CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT]:
        COMETCHAT_CONSTANTS.PARTICIPANT,
      };
    },
    /**
     * Computes if can unban.
     */
    canUnBan() {
      let unBan = true;

      if (
          this.item.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR &&
          (this.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN ||
              this.member.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR)
      ) {
        unBan = false;
      }

      if (
          this.item.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN &&
          this.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN
      ) {
        if (this.item.owner !== this.loggedInUser.uid) {
          unBan = false;
        }
      }

      return unBan;
    },
  },
};
</script>
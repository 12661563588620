import Vue from 'vue'
import api from "@/lib/api";
import apiHatchery from "@/lib/apiHatchery";
import router from "@/router/router";
import i18n from '../../i18n'

const defaultAnalysis = {
  data: null,
  date: new Date(),
  loading: false,
  products: {
    data: {
      data: null,
      probiotics: null
    },
    loading: false,
    opts: {
      total_products: [],
    }
  },
  water_quality: {
    data: null,
    recommendation: null,
    loading: false,
    opts: {
      total_general: [],
    },
  },
  anco: {
    data: null,
    loading: false,
    opts: {
      total_anco: 0,
      total_anco_times: 0,
    }
  },
  sampling: {
    data: null,
    loading: false,
    opts: {}
  },
}

export default {
  namespaced: true,
  state: {
    farm: [],
    selectedFarm: {
      blocks: [],
      ponds: [],
      permissions: [],
      farm_id: null,
      info: {
        block_count: 0,
        cycle_count: 0,
        pond_count: 0,
        stock_count: 0,
        user_count: 0,
      },
      is_hide_finance: false,
    },
    loading: false,

    tours: {
      showBackToMenuTours: false,
    },

    analysis: JSON.parse(JSON.stringify(defaultAnalysis)),

    overview: {
      refresh_data: false,
      ponds: []
    },

    warehouse: {
      categories: [],
      refresh_data: false,
      unit: [
        'Kg',
        'Liter',
        'Gram',
        'Ml',
        'Unit',
        'Set',
      ]
    },

    roles: [],
  },
  mutations: {
    UPDATE_TOURS(state, {tour_name, value = true}) {
      state.tours[tour_name] = value
    },

    UPDATE_FARM(state, payload = null) {
      state.farm = payload && payload.length ?
        payload.sort((a, b) => a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })).map(e => {
          e.canEditFarm = [1, 2].includes(e.role_id)
          return e
        }) :
        []
    },

    UPDATE_SELECTED_FARM(state, payload = null) {
      let analysis = JSON.parse(JSON.stringify(defaultAnalysis))
      analysis.date = Vue.prototype.$startDay().toDate()
      state.analysis = analysis

      if (payload)
        state.selectedFarm = {
          ...payload,
          permissions: [],
          blocks: [],
          ponds: [],
          info: {
            block_count: 0,
            cycle_count: 0,
            pond_count: 0,
            stock_count: 0,
            user_count: 0,
          }
        }
      else
        state.selectedFarm = {
          blocks: [],
          ponds: [],
          info: {
            block_count: 0,
            cycle_count: 0,
            pond_count: 0,
            stock_count: 0,
            user_count: 0,
          }
        }
    },
    UPDATE_SELECTED_FARM_INFO(state, payload = null) {
      state.selectedFarm.info = payload
    },
    UPDATE_SELECTED_FARM_BLOCKS(state, payload = []) {
      state.selectedFarm = {
        ...state.selectedFarm,
        blocks: payload.sort((a, b) => a.block_name.localeCompare(b.block_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }))
      }
    },
    UPDATE_SELECTED_FARM_PONDS(state, payload = []) {
      let ponds = payload.sort((a, b) => a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))

      let blocks = state.selectedFarm.blocks

      if (state.selectedFarm.role_id > 2) {
        let access_pond = state.selectedFarm.access_pond.map(e => e.pond_id)
        ponds = ponds.filter(e => access_pond.includes(e.pond_id))

        let block_ids = ponds.reduce((a, b) => {
          if (!a.includes(b.block_id)) a.push(b.block_id)
          return a
        }, [])

        blocks = blocks.filter(e => block_ids.includes(e.block_id))
      }

      state.selectedFarm = {
        ...state.selectedFarm,
        ponds: ponds,
        blocks: blocks,
      }
    },

    UPDATE_SELECTED_FARM_STATUS_DATE(state, payload) {
      state.analysis = {
        ...JSON.parse(JSON.stringify(defaultAnalysis)),
        date: payload
      }
    },

    CHANGE_POND_STATUS(state, payload) {
      const {pond_id, status} = payload
      let overview_pond = state.overview.ponds.find(e => e.pond_id === pond_id)
      let pond = state.selectedFarm.ponds.find(e => e.pond_id === pond_id)

      if (overview_pond && pond) {
        overview_pond.status = status
      }
    },

    UPDATE_OVERVIEW_PONDS(state, payload = []) {
      state.overview.ponds = payload
    },

    REFRESH_OVERVIEW(state, payload = true) {
      state.overview.refresh_data = payload
    },

    REFRESH_WAREHOUSE(state, payload = true) {
      state.warehouse.refresh_data = payload
    },

    UPDATE_WAREHOUSE_CATEGORY(state, payload = []) {
      const find_category = (item, name) => {
        return item.find(e => e.category_name === name)
      }

      let resort = [
        find_category(payload, 'Feed'),
        find_category(payload, 'Probiotics'),
        find_category(payload, 'Chemical'),
        find_category(payload, 'Lab'),
        find_category(payload, 'Equipment'),
        find_category(payload, 'Electric'),
        find_category(payload, 'More'),
      ]

      state.warehouse.categories = resort.filter(e => e).map(e => {
        e.category_name = i18n.t('warehouse.' + e.category_name.toLowerCase())
        return e
      })
    },

    UPDATE_WATER_ANALYSIS(state, {loading = false, data = null, recommendation = null, opts = {}}) {
      if (data) state.analysis.water_quality.data = data
      if (recommendation) state.analysis.water_quality.recommendation = data
      state.analysis.water_quality.opts = opts
      state.analysis.water_quality.loading = loading
    },
    UPDATE_ANCO_ANALYSIS(state, {loading = false, data = null, opts = {}}) {
      if (data) state.analysis.anco.data = data
      state.analysis.anco.opts = opts
      state.analysis.anco.loading = loading
    },
    UPDATE_SAMPLING_ANALYSIS(state, {loading = false, data = null, opts = {}}) {
      if (data) state.analysis.sampling.data = data
      state.analysis.sampling.opts = opts
      state.analysis.sampling.loading = loading
    },
    UPDATE_PRODUCTS_ANALYSIS(state, {loading = false, data = null, opts = {}}) {
      if (data) state.analysis.products.data = data
      state.analysis.products.loading = loading
      state.analysis.products.opts = opts
    },
    UPDATE_FARM_STATUS(state, {loading = false, data = null}) {
      if (data) state.analysis.data = data
      state.analysis.loading = loading
    },
  },
  actions: {

    // REALTIME ANALYSIS

    updateSelectedFarmStatusDate({commit}, payload) {
      commit('UPDATE_SELECTED_FARM_STATUS_DATE', payload)
    },

    // OVERVIEW

    refreshOverview({commit}, payload = true) {
      commit('REFRESH_OVERVIEW', payload)
    },

    // FARM

    async getFarm({state, commit, dispatch}) {
      state.loading = true
      Vue.prototype.$loading()

      let res = await api.get('/farm').catch(() => {
        router.push('/farm-registration')
      })
      if (!res.error && res.data) {
        if (res.data.length) {
          commit('UPDATE_FARM', res.data)

          let currentUrl = router.history.current

          let farm = state.farm[0]

          if (currentUrl.params.farm_id) {
            let find = state.farm.find(e => e.farm_id == currentUrl.params.farm_id)
            if (find) {
              farm = find
            }
          }

          await dispatch('selectFarm', farm)
        } else {
          router.push('/farm-registration')
        }
      } else {
        router.push('/farm-registration')
      }

      Vue.prototype.$loading(false)
      Vue.prototype.$nextTick(() => {
        setTimeout(() => {
          state.loading = false

        }, 0)
      })
    },
    async getInternalFarm(context) {
      let res = await api.get('/internal/farm')
      if (!res.error && res.data) {
        if (res.data.length) {
          return res
        }
      }
      return null
    },
    getFarmInfo({state, commit}) {
      commit('UPDATE_SELECTED_FARM_INFO')
      return new Promise((resolve, reject) => {
        api.get('/farm/' + state.selectedFarm.farm_id + '/info').then(res => {
          if (!res.error) {
            commit('UPDATE_SELECTED_FARM_INFO', res.data)
            resolve(res.data)
          } else resolve(null)
        }).catch(err => {
          reject(err)
        })
      })
    },
    async selectFarm({state, dispatch, commit}, payload) {
      state.loading = true

      commit('UPDATE_SELECTED_FARM', payload)
      commit('UPDATE_SELECTED_FARM_BLOCKS')
      commit('UPDATE_SELECTED_FARM_PONDS')
      commit('UPDATE_OVERVIEW_PONDS', [])

      if (router.currentRoute.params.farm_id != payload.farm_id) {
        localStorage.removeItem('sgara.pgsql.input.wq.data')
        localStorage.removeItem('sgara.pgsql.input.wq.date')
        localStorage.removeItem('sgara.pgsql.input.wq.times')
        localStorage.removeItem('sgara.pgsql.input.feed.data')
        localStorage.removeItem('sgara.pgsql.input.feed.date')
        localStorage.removeItem('sgara.pgsql.input.feed.times')
        localStorage.removeItem('sgara.pgsql.input.probiotics.data')
        localStorage.removeItem('sgara.pgsql.input.probiotics-free.data')
        localStorage.removeItem('sgara.pgsql.input.probiotics-free.date')
        localStorage.removeItem('sgara.pgsql.input.probiotics-free.products')
        localStorage.removeItem('sgara.pgsql.input.probiotics.date')
        localStorage.removeItem('sgara.pgsql.input.probiotics.times')
      }

      let permissions = await dispatch('getPermission', payload.role_id)
      Vue.prototype.$loading()

      if (!permissions.error && permissions.data && permissions.data.length) {
        state.selectedFarm.permissions = permissions.data
      }

      if (router.history.current.params.farm_id) {
        router.replace({
          params: {
            farm_id: state.selectedFarm.farm_id
          },
          query: router.currentRoute.query,
        }).catch(() => {
        })
      } else {
        router.push(`/farm/${state.selectedFarm.farm_id}`).catch(() => {
        })
      }

      await dispatch('getBlock', payload.farm_id)
      await dispatch('getPond', payload.farm_id)
      await dispatch('getFarmInfo')

      Vue.prototype.$loading(false)

      Vue.prototype.$nextTick(() => {
        setTimeout(() => {
          state.loading = false
        }, 0)
      })
    },
    addFarm(context, payload) {
      return api.post('/farm/add', payload, false)
    },
    updateFarm(context, payload) {
      return api.put('/farm/' + payload.farm_id + '/update', payload.data, false)
    },
    async deleteFarm({dispatch}, id) {
      await api.destroy('/farm/' + id + '/delete')
      await dispatch('getFarm')
    },
    async leaveFarm({dispatch}, id) {
      await api.post('/farm/' + id + '/leave')
      router.push('/farm')
      dispatch('getFarm')
    },

    // Block

    async getBlock({commit, state}, farm_id = null) {
      farm_id = farm_id ? farm_id : state.selectedFarm.farm_id
      let blocks = await api.get('/block', {farm_id: farm_id})
      if (!blocks.error && blocks.data) {
        commit('UPDATE_SELECTED_FARM_BLOCKS', blocks.data)
      }
      return blocks
    },
    addBlock(context, payload) {
      return api.post('/block', payload, false)
    },
    updateBlock(context, payload) {
      return api.put('/block/' + payload.block_id + '/update', payload, false)
    },
    deleteBlock(context, id) {
      return api.destroy('/block/' + id + '/delete')
    },

    // Pond

    async getPond({commit, state}, farm_id = null) {
      farm_id = farm_id ? farm_id : state.selectedFarm.farm_id
      let ponds = await api.get('/pond', {farm_id: farm_id})
      if (!ponds.error && ponds.data) {
        commit('UPDATE_SELECTED_FARM_PONDS', ponds.data)
      }
      return ponds
    },
    addPond(context, payload) {
      return api.post('/pond', payload, false)
    },
    updatePond(context, payload) {
      return api.put('/pond/' + payload.pond_id + '/update', payload, false)
    },
    deletePond(context, id) {
      return api.destroy('/pond/' + id + '/delete')
    },

    changePondStatus({commit}, payload) {
      commit('CHANGE_POND_STATUS', payload)
    },

    // Cycle

    getCycle(context, pond_id) {
      return api.get('/cycle', {pond_id})
    },
    getCycleById(context, cycle_id) {
      return api.get('/cycle', {cycle_id})
    },
    async getCycleOverview({dispatch, state, getters}, {pond, refresh = false}) {
      let find = state.selectedFarm.ponds.find(e => e.pond_id === pond.pond_id)

      if (!refresh) {
        if (find.cycles) {
          pond.cycles = find.cycles
          return find.cycles
        }
      }

      let cycles = await dispatch('getCycle', pond.pond_id)
      if (!cycles.error && cycles.data && cycles.data.length) {
        pond.cycles = cycles.data.sort((a, b) => b.start_date > a.start_date ? 1 : -1).map((e, k) => {
          e.cycle_name = (getters.isPro ? i18n.t('cycle.cycle') : i18n.t('stock.stock')) + ' #' + (cycles.data.length - k)
          e.pond_name = pond.name
          return e
        })
        return pond.cycles
      }
      return []
    },
    async getActiveCycle({dispatch}, pond_id) {
      let res = await dispatch("getCycle", pond_id)
      if (!res.error && res.data) {
        let cycle = res.data

        if (cycle.length) {
          return cycle.find(e => e.end_date === 0)
        }
      }
      return null
    },
    startCycle(context, payload) {
      return api.post('/cycle', payload, false)
    },
    updateCycle(context, payload) {
      return api.put('/cycle/' + payload.cycle_id + '/update', payload, false)
    },
    endCycle(context, payload) {
      return api.post('/cycle/' + payload.cycle_id + '/end', payload, false)
    },
    deleteCycle(context, id) {
      return api.destroy('/cycle/' + id + '/delete')
    },

    // Stock

    getStock(context, pond_id) {
      return api.get('/stock', {pond_id})
    },
    getStockById(context, stock_id) {
      return api.get('/stock', {stock_id})
    },
    async getStockOverview({dispatch}, pond) {
      let stock = await dispatch('getStock', pond.pond_id)
      if (!stock.error && stock.data && stock.data.length) {
        pond.stock = stock.data.map(e => {
          e.pond_name = pond.name
          return e
        })
        return pond.stock
      }
      return []
    },
    async getActiveStock({dispatch}, pond_id) {
      let res = await dispatch('getStock', pond_id)
      if (!res.error && res.data) {
        let stock = res.data

        if (stock.length) return stock.find(e => e.is_harvest === false)
      }
      return null
    },
    addStock(context, payload) {
      return api.post('/stock', payload, false)
    },
    updateStock(context, payload) {
      return api.put('/stock/' + payload.stock_id + '/update', payload, false)
    },
    updateStockCycle(context, payload) {
      return api.put('/stock-cycle/' + payload.stock_id + '/update', payload, false)
    },
    deleteStock(context, id) {
      return api.destroy('/stock/' + id + '/delete')
    },
    deleteStockCycle(context, id) {
      return api.destroy('/stock-cycle/' + id + '/delete')
    },

    // Harvest

    addHarvest(context, payload) {
      return api.post('/harvest', payload, false)
    },
    updateHarvest(context, payload) {
      return api.put('/harvest/' + payload.harvest_id + '/update', payload, false)
    },
    deleteHarvest(context, id) {
      return api.destroy('/harvest/' + id + '/delete')
    },
    getHarvest(context, stock_id) {
      return api.get('/harvest', {stock_id: stock_id})
    },

    // Disease

    getDiseaseMaster() {
      return api.get('/master/disease')
    },
    getDisease(context, cycle_id) {
      return api.get('/disease', {cycle_id: cycle_id})
    },
    addDisease(context, payload) {
      return api.post('/disease', payload, false)
    },
    updateDisease(context, payload) {
      return api.put('/disease/' + payload.disease_id + '/update', payload, false)
    },
    deleteDisease(context, id) {
      return api.destroy('/disease/' + id + '/delete')
    },
    deleteDiseasePhoto(context, photo_id) {
      return api.destroy('/disease/photo/' + photo_id + '/delete')
    },

    // Sampling

    addSampling(context, payload) {
      return api.post('/sampling/bulk', payload, false)
    },
    updateSampling(context, payload) {
      return api.put('/sampling/' + payload.sampling_id + '/update', payload, false)
    },
    deleteSampling(context, id) {
      return api.destroy('/sampling/' + id + '/delete')
    },
    deleteSamplingPhoto(context, photo_id) {
      return api.destroy('/sampling/photo/' + photo_id + '/delete')
    },
    getSampling(context, payload) {
      return api.get('/sampling', {
        farm_id: payload.farm_id,
        pond_id: payload.pond_id,
        cycle_id: payload.cycle_id,
        timediff: 0,
      })
    },

    // Shrimp Death

    addDeath(context, payload) {
      return api.post('/death', payload, false)
    },
    updateDeath(context, payload) {
      return api.put('/death/' + payload.death_id + '/update', payload, false)
    },
    getDeath(context, stock_id) {
      return api.get('/death', {stock_id: stock_id})
    },
    deleteDeath(context, id) {
      return api.destroy('/death/' + id + '/delete')
    },

    // Notes

    addNote(context, payload) {
      return api.post('/note', payload, false)
    },
    updateNote(context, payload) {
      return api.put('/note/' + payload.note_id + '/update', payload, false)
    },
    getNote(context, farm_id) {
      return api.get('/note', {farm_id: farm_id})
    },
    deleteNote(context, id) {
      return api.destroy('/note/' + id + '/delete')
    },

    // Water Change

    addWaterChange(context, payload) {
      return api.post('/waterChange/bulk', payload, false)
    },
    updateWaterChange(context, payload) {
      return api.put('/waterChange/' + payload.water_change_id + '/update', payload, false)
    },
    getWaterChange(context, cycle_id) {
      return api.get('/waterChange', {cycle_id: cycle_id})
    },
    deleteWaterChange(context, id) {
      return api.destroy('/waterChange/' + id + '/delete')
    },

    // Anco

    getAnco(context, payload) {
      return api.get('/anco', payload)
    },
    addAnco(context, payload) {
      return api.post('/anco/bulk', payload, false)
    },
    async updateAnco(context, payload) {
      const {anco_id} = payload
      return api.put('/anco/' + anco_id + '/update', payload, false)
    },
    deleteAnco(context, anco_id) {
      return api.destroy('/anco/' + anco_id + '/delete')
    },

    // Water Quality

    getWaterQualityGeneral(context, payload) {
      return api.get('/water/general', payload)
    },
    addWaterQualityGeneral(context, payload) {
      return api.post('/water/general/bulk', payload, false)
    },
    updateWaterQualityGeneral(context, payload) {
      return api.put('/water/general/' + payload.general_id + '/update', payload, false)
    },
    deleteWaterQualityGeneral(context, id) {
      return api.destroy('/water/general/' + id + '/delete')
    },
    getWaterQualityChemical(context, payload) {
      return api.get('/water/chemical', payload)
    },
    addWaterQualityChemical(context, payload) {
      return api.post('/water/chemical/bulk', payload, false)
    },
    updateWaterQualityChemical(context, payload) {
      return api.put('/water/chemical/' + payload.chemical_id + '/update', payload, false)
    },
    deleteWaterQualityChemical(context, id) {
      return api.destroy('/water/chemical/' + id + '/delete')
    },
    getWaterQualityBiological(context, payload) {
      return api.get('/water/biological', payload)
    },
    addWaterQualityBiological(context, payload) {
      return api.post('/water/biological/bulk', payload, false)
    },
    updateWaterQualityBiological(context, payload) {
      return api.put('/water/biological/' + payload.biological_id + '/update', payload, false)
    },
    deleteWaterQualityBiological(context, id) {
      return api.destroy('/water/biological/' + id + '/delete')
    },

    // Water Quality Setting

    getWqs(context, farm_id) {
      return api.get('/waterSetting', {farm_id: farm_id})
    },
    addWqs(context, payload) {
      return api.post('/waterSetting', payload, false)
    },
    deleteWqs(context, wqs_id) {
      return api.destroy('/waterSetting/' + wqs_id + '/delete')
    },


    // Feed Index Setting

    getFeedIndexSetting(context, farm_id) {
      return api.get('/feedIdxSetting', {farm_id: farm_id})
    },
    updateFeedIndexSetting(context, payload) {
      return api.post('/feedIdxSetting', payload, false)
    },


    // Feeding Rate

    getFR(context, farm_id) {
      return api.get('/fr', {farm_id: farm_id})
    },
    updateFR(context, payload) {
      return api.post('/fr', payload, false)
    },


    // Water Quality Chart

    getWaterQualityChart(context, payload) {
      return api.get('/chart/water', payload)
    },

    // Product Chart

    getFeedChart(context, payload) {
      return api.get('/chart/feed', {
        ...payload,
        timediff: 0,
      })
    },
    getFeedAccumulativeChart(context, payload) {
      return api.get('/chart/feedAccumulative', {
        ...payload,
        timediff: 0,
      })
    },
    getUsedItemPrice(context, payload) {
      return api.get('/chart/usagePrice', payload)
    },
    getUsedItemList(context, payload) {
      return api.get('/useitem/products', payload)
    },

    // User
    getPermission(context, role_id) {
      return api.get('/permission', {role_id})
    },
    async getRole({state}) {
      state.roles = []
      let res = await api.get('/role')
      if (!res.error) {
        state.roles = res.data
      }
      return res
    },
    getUser({state}, farm_id = null) {
      if (!farm_id) farm_id = state.selectedFarm.farm_id
      if (farm_id) return api.get('/userFarm', {farm_id})
      return {}
    },
    addUserFarm(context, payload) {
      return api.post('/userFarm', payload, false)
    },
    async updateUserFarm(context, payload) {
      const {user_farm_id} = payload
      return api.put('/userFarm/' + user_farm_id + '/update', payload, false)
    },
    deleteUserFarm(context, user_farm_id) {
      return api.destroy('/userFarm/' + user_farm_id + '/delete')
    },

    // Warehouse

    refreshWarehouse({commit}, payload = true) {
      commit('REFRESH_WAREHOUSE', payload)
    },
    async getWarehouseCategory({commit, state}) {
      if (!state.warehouse.categories || !state.warehouse.categories.length) {
        let res = await api.get('/category')
        if (!res.error && res.data && res.data.length) {
          commit('UPDATE_WAREHOUSE_CATEGORY', res.data)
        }
      }
    },
    getWarehouse(context, payload) {
      return api.get('/warehouse', payload)
    },
    getProducts(context, {search = null}) {
      return new Promise((resolve, reject) => {
        api.get('/products', {
          page: 1,
          search,
          order: 'asc',
          order_by: 'name',
        }).then(res => {
          if (res.data.data) {
            resolve(res.data)
          } else {
            reject(false)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    searchWarehouse(context, payload) {
      return api.get('/searchFarmWarehouse', payload)
    },
    getWarehouseDetail(context, payload) {
      const {farm_id, warehouse_id} = payload

      return api.get('/warehouse', {
        farm_id: farm_id,
        warehouse_id: warehouse_id,
      })
    },
    getWarehouseHistory(context, payload) {
      return api.get('/warehouse/history', payload)
    },
    addWarehouse(context, payload) {
      return api.post('/warehouse', payload, false)
    },
    updateWarehouse(context, payload) {
      return api.put('/warehouse/' + payload.warehouse_id + '/update', payload, false)
    },
    deleteWarehouse(context, warehouse_id) {
      return api.destroy('/warehouse/' + warehouse_id + '/delete')
    },
    useItem(context, payload) {
      return api.post('/useitem/bulk', payload, false)
    },
    async updateUseItem(context, payload) {
      const {used_sub_id} = payload
      return api.put('/useitem/' + used_sub_id + '/update', payload, false)
    },
    async deleteUseItem(context, payload) {
      const {used_sub_id} = payload
      return api.destroy('/useitem/' + used_sub_id + '/delete')
    },
    restockItem(context, payload) {
      return api.post('/warehouse/' + payload.warehouse_id + '/store', payload)
    },
    async updateRestockItem(context, payload) {
      const {warehouse_id, used_sub_id} = payload
      return api.put('/warehouse/' + warehouse_id + '/store/' + used_sub_id + '/update', payload, false)
    },
    async deleteRestockItem(context, payload) {
      const {warehouse_id, used_sub_id} = payload
      return api.destroy('/warehouse/' + warehouse_id + '/store/' + used_sub_id + '/delete')
    },
    removeItem(context, payload) {
      return api.post('/warehouse/' + payload.warehouse_id + '/remove', payload)
    },
    async updateRemoveItem(context, payload) {
      const {warehouse_id, used_sub_id} = payload
      return api.put('/warehouse/' + warehouse_id + '/remove/' + used_sub_id + '/update', payload, false)
    },
    async deleteRemoveItem(context, payload) {
      const {warehouse_id, used_sub_id} = payload
      return api.destroy('/warehouse/' + warehouse_id + '/remove/' + used_sub_id + '/delete')
    },
    addPurchaseOrder(context, payload) {
      return api.post('/farm/purchase-order', payload, false)
    },
    sendPurchaseOrderToWhatsapp(context, po_id) {
      return api.post('/farm/purchase-order/' + po_id + '/whatsapp')
    },
    getPurchaseOrder({state}, payload) {
      return api.get('/purchase-order', {
        page: 1,
        farm_id: state.selectedFarm.farm_id,
      })
    },


    // Daily Analysis

    async getWaterQualityAnalysis({state, commit}, payload = {}) {
      let {date = null, pond_ids = null, updateState = true} = payload

      if (updateState) {
        commit('UPDATE_WATER_ANALYSIS', {
          loading: true,
          data: [],
          opts: {
            total_general: [],
          }
        })
      }
      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null

      let data = {
        farm_id, start_date,
      }

      if (pond_ids) data.ponds = pond_ids.join(',')
      await api.get('/farm/water', data).then(res => {
        let data = (res.data && res.data.ponds && res.data.ponds.length ? res.data.ponds : [])

        if (pond_ids) data = data.filter(e => pond_ids.includes(e.pond_id))

        let opts = {
          total_general: []
        }

        opts.total_general = res.data.ponds.reduce((a, b) => {
          if (b.general) return a.length > b.general.length ? a : b.general
          return a
        }, []).sort((a, b) => a.created_at > b.created_at ? 1 : -1)

        data = data.map(e => {
          if (e.general && e.general.length) {
            e.general_morning = e.general.find(f => parseInt(Vue.prototype.$timeFormat(f.created_at)) < 12)
            e.general_afternoon = e.general.find(f => parseInt(Vue.prototype.$timeFormat(f.created_at)) >= 12 && parseInt(Vue.prototype.$timeFormat(f.created_at)) < 18)
            e.general_evening = e.general.find(f => parseInt(Vue.prototype.$timeFormat(f.created_at)) >= 18)
          }

          let general_data = []

          if (e.general) {
            for (let i = 0; i < opts.total_general.length; i++) {
              if (e.general) {
                let findGeneral = e.general.find(e => e.created_at === opts.total_general[i].created_at)
                if (findGeneral) general_data.push(findGeneral)
                else general_data.push({
                  show: false,
                })
              }
            }
          }

          e.general = general_data

          e.general_time = []

          let general = ['ph', 'DO', 'temperature', 'turbidity', 'water_height', 'salinity', 'oxygen', 'orp', 'color', 'weather']

          for (let i = 0; i < opts.total_general.length; i++) {
            let time = ({
              time: opts.total_general[i].created_at,
              data: {},
            })
            let find = e.general.find(f => f.created_at === opts.total_general[i].created_at)
            for (let j = 0; j < general.length; j++) {
              if (find) {
                let value = find[general[j]]
                if (value && value !== -99) time.data[general[j]] = true
              }
            }
            e.general_time.push(time)
          }


          let is_chemical = false

          let chemical = [
            'tom',
            'alkalinity',
            'po4',
            'no3',
            'no2',
            'nh4',
            'nh3',
            'tan',
            'co3',
            'hco3',
            'ca',
            'mg',
            'hard',
          ]

          let bacteria = [
            'tbc',
            'tvc',
            'tvctbc',
            'yvc',
            'gvc',
            'bvc',
            'lbc',
            'bga',
            'ga',
            'gga',
            'dino',
            'dia',
            'pro',
            'eug',
            'plankton',
          ]

          if (e.general.length) e.cycle_id = e.general[0].cycle_id
          else if (e.chemical.length) e.cycle_id = e.chemical[0].cycle_id
          else if (e.bacteria.length) e.cycle_id = e.bacteria[0].cycle_id

          return e
        })

        if (updateState) {
          commit('UPDATE_WATER_ANALYSIS', {
            loading: false,
            data: data,
            opts,
          })
        }

        response = {
          data,
          opts,
        }
      })

      return response
    },
    async getWaterQualityStandar({state, commit}, payload = {}) {
      let {date = null, pond_ids = null} = payload

      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null
      let data = {
        farm_id, start_date,
      }

      if (pond_ids) data.ponds = pond_ids.join(',')
      await api.get('/farm/water/standar', data).then(res => {
        if (!res.error && res.data) {
          if (pond_ids) res.data = res.data.filter(e => pond_ids.includes(e.pond_id))
          response = res.data
        }
      })

      return response
    },
    async getProductsAnalysis({state, commit}, payload = {}) {
      let {date = null, pond_ids = null} = payload

      commit('UPDATE_PRODUCTS_ANALYSIS', {
        loading: true,
        data: [],
        opts: {
          total_products: [],
        }
      })
      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null
      let data = {
        farm_id, start_date,
      }
      if (pond_ids) data.ponds = pond_ids.join(',')

      await api.get('/farm/product', data).then(res => {
        let data = []
        let probiotics = []
        let opts = {
          total_products: [],
        }

        if (res.data && res.data.length) {
          let cat_ids = [3, 6]

          res.data = res.data.map(e => {
            e.qty = Vue.prototype.$number(e.qty, 3)
            return e
          })

          if (pond_ids) res.data = res.data.filter(e => pond_ids.includes(e.pond_id))

          probiotics = res.data.reduce((a, b) => {
            if (cat_ids.includes(b.category_id)) {
              let find = a.find(e => e.warehouse_id === b.warehouse_id)
              if (!find) a.push(b)
            }
            return a
          }, []).sort((a, b) => a.product_name.localeCompare(b.product_name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }))

          let feed = res.data.filter(e => e.category_id === 5)
          data = state.selectedFarm.ponds.map(e => {
            return {
              pond_id: e.pond_id,
              pond_name: e.name,
              block_id: e.block_id,
              block_name: e.block_name,
              data: feed.filter(f => f.pond_id === e.pond_id),
            }
          }).map(e => {
            e.data = e.data.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
            let currentTime = 0
            e.data = e.data.reduce((a, b) => {
              let find = a.find(f => f.warehouse_id === b.warehouse_id)

              let item = Vue.prototype._.cloneDeep({
                used_sub_id: b.used_sub_id,
                created_at: b.created_at,
                qty: b.qty,
                unit: b.unit,
                warehouse_id: b.warehouse_id,
                product_id: b.product_id,
                product_name: b.product_name,
                category_id: b.category_id,
                category_name: b.category_name,
                cycle_id: b.cycle_id,
                pond_id: b.pond_id,
                block_id: b.block_id,
              })

              if (find) {
                find.qty_times[currentTime] = item
              } else {
                let newItem = Vue.prototype._.cloneDeep(b)
                newItem.qty_times = []
                newItem.qty_times[currentTime] = item
                a.push(newItem)
              }

              ++currentTime

              return a
            }, [])

            opts.total_products = opts.total_products > currentTime ? opts.total_products : currentTime

            let probiotics_data = res.data.filter(f => f.pond_id === e.pond_id && cat_ids.includes(f.category_id))

            e.probiotics = Vue.prototype._.cloneDeep(probiotics).map(f => {
              f.cycle_id = null
              f.qty_used = probiotics_data.reduce((a, b) => {
                if (b.warehouse_id === f.warehouse_id) {
                  f.created_at = b.created_at
                  f.used_sub_id = b.used_sub_id
                  f.cycle_id = b.cycle_id
                  a += b.qty
                }
                return a
              }, 0)

              return f
            })

            e.total_qty = e.data.reduce((a, b) => {
              return a + b.qty_times.reduce((c, d) => c + d.qty, 0)
            }, 0)

            return e
          }).filter(e => e.data.length || (e.probiotics.length && e.probiotics.find(f => f.qty_used))).sort((a, b) => a.pond_name.localeCompare(b.pond_name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }))
        }

        commit('UPDATE_PRODUCTS_ANALYSIS', {
          loading: false,
          data: {
            data,
            probiotics,
          },
          opts
        })

        response = {
          data: {
            data,
            probiotics,
          },
          opts,
        }
      })

      return response
    },
    async getProductsAnalysisCumulative({state, commit}, payload = {}) {
      let {date = null, pond_ids = null, categories = []} = payload

      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null
      let data = {
        farm_id, start_date,
        category_ids: categories.join(',')
      }
      if (pond_ids) data.ponds = pond_ids.join(',')

      await api.get('/farm/product/cumulative', data).then(res => {
        if (!res.error) {
          response = res.data
        }
      })

      return response
    },
    async getAncoAnalysis({state, commit, getters}, payload = {}) {
      let {date = null, pond_ids = null} = payload

      commit('UPDATE_ANCO_ANALYSIS', {
        loading: true,
        data: [],
        opts: {
          total_anco: 0,
          total_anco_times: 0,
        }
      })
      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null

      await api.get('/farm/anco', {
        farm_id, start_date,
      }).then(res => {
        let data = []
        let totalAnco = 0
        let totalAncoTimes = 0

        if (res.data && res.data.length) {
          if (pond_ids) res.data = res.data.filter(e => pond_ids.includes(e.pond_id))

          totalAnco = res.data.reduce((a, b) => {
            return b.data_anco ? (b.data_anco.length > a ? b.data_anco.length : a) : a
          }, 0)
          data = res.data.reduce((a, b) => {
            let find = a.find(e => e.pond_id === b.pond_id)

            b.edit = {}
            b.is_edit = false

            let pond = getters.getPondById(b.pond_id)

            if (pond.anco < totalAnco) {
              for (let i = 0; i < totalAnco - pond.anco; i++) {
                b.data_anco.push(null)
              }
            }

            if (find) {
              find.data.push(b)
            } else {
              a.push({
                stock_id: b.stock_id,
                pond_id: b.pond_id,
                pond_name: pond.name,
                data: [b],
              })
            }

            return a
          }, []).sort((a, b) => a.pond_name.localeCompare(b.pond_name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })).map(e => {
            totalAncoTimes = totalAncoTimes > e.data.length ? totalAncoTimes : e.data.length
            e.data = e.data.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
            return e
          })
        }

        commit('UPDATE_ANCO_ANALYSIS', {
          loading: false,
          data: data,
          opts: {
            total_anco: totalAnco,
            total_anco_times: totalAncoTimes,
          }
        })

        response = {
          data: data,
          opts: {
            total_anco: totalAnco,
            total_anco_times: totalAncoTimes,
          }
        }
      })

      return response
    },
    async getDeathByFarm({state, commit, getters}, payload = {}) {
      let {date = null, pond_ids = null} = payload

      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null

      await api.get('/death/farm', {
        farm_id, start_date,
      }).then(res => {
        if (res.data && res.data.length) {
          if (pond_ids) res.data = res.data.filter(e => pond_ids.includes(e.pond_id))

          res.data = res.data.sort((a, b) => a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }))

          response = res.data
        }
      })

      return response
    },
    async getDeathByFarmCumulativeBefore({state, commit, getters}, payload = {}) {
      let {date = null, pond_ids = null} = payload

      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null

      await api.get('/death/farm/cumulative/before', {
        farm_id, start_date,
      }).then(res => {
        if (res.data && res.data.length) {
          if (pond_ids) res.data = res.data.filter(e => pond_ids.includes(e.pond_id))

          res.data = res.data.sort((a, b) => a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }))

          response = res.data
        }
      })

      return response
    },
    async getSamplingChartAnalysis({state, commit}, {from, to, ponds = null}) {
      const farm_id = state.selectedFarm.farm_id

      let response = null

      let data = {
        from,
        to,
      }

      if (ponds) data.ponds = ponds.join(',')
      else data.farm_id = farm_id

      data.timediff = 0

      await api.get('/chart/sampling', data).then(res => {
        if (res.data && res.data.length) {
          response = res.data
        }
      })

      return response
    },

    async getFeedChartDaily({state}, {date = null, pond_ids = null}) {
      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      return await api.get('/chart/feed/daily', {
        ponds: pond_ids,
        farm_id, start_date,
        timediff: 0,
      })
    },

    async getAccumulativeChartDaily({state}, {date = null, pond_ids = null}) {
      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      return await api.get('/chart/feed/accumulative/daily', {
        ponds: pond_ids,
        farm_id, start_date,
        timediff: 0,
      })
    },

    async getFeedWeekly({state}, date = null) {
      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      return await api.get('/farm/product/weekly', {
        farm_id, start_date,
        timediff: 0,
      })
    },

    async getSamplingAnalysis({state, commit, getters}, date = null) {
      commit('UPDATE_SAMPLING_ANALYSIS', {
        loading: true,
        data: [],
        opts: {}
      })
      let start_date = Vue.prototype.$timestamp(date || state.analysis.date)
      const farm_id = state.selectedFarm.farm_id

      let response = null

      await api.get('/shrimData', {
        farm_id,
        start_date,
        timediff: 0,
      }).then(res => {
        let data = []

        if (res.data && res.data.length) {
          data = res.data.filter(e => e.samplings && e.samplings.sampling_id).map((e, k) => {
            e.pond_name = e.name
            e.num = k
            return e
          }).sort((a, b) => a.pond_name.localeCompare(b.pond_name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }))
        }

        commit('UPDATE_SAMPLING_ANALYSIS', {
          loading: false,
          data: data,
          opts: {}
        })

        response = {
          data: data,
          opts: {}
        }
      })

      return response
    },
    async getFarmStatus({state, commit}, date = null) {
      commit('UPDATE_FARM_STATUS', {
        loading: true,
        data: [],
      })

      if (date) state.analysis.date = date

      let start_date = Vue.prototype.$moment(state.analysis.date).startOf('day')
      start_date = Vue.prototype.$timestamp(start_date)
      const farm_id = state.selectedFarm.farm_id
      if (farm_id) {
        return api.get('/farm/status', {
          farm_id,
          start_date,
          lang: i18n.locale,
        }).then(res => {
          let data = res.data ? res.data : null

          commit('UPDATE_FARM_STATUS', {
            loading: false,
            data: data
          })
        })
      }
      return null
    },

    countPl({commit, rootState}, {image_url, hatch_color}) {
      return new Promise((resolve, reject) => {
        let data = new FormData
        data.append('file', image_url)

        apiHatchery.post('/cv/upload', data, false).then(res => {
          let result_url = res.data[0].image_url.replace(' ', '%20')

          api.post('/stock/count-pl', {
            image_url: result_url,
            hatch_color,
          }).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },
  },

  getters: {
    selectedFarm: state => state.selectedFarm,
    getPondById: (state) => (id) => {
      return state.selectedFarm.ponds.find(e => e.pond_id === id) || {}
    },
    isPro: state => state.selectedFarm.status,
    isFree: state => !state.selectedFarm.status,
    hideFinance: state => state.selectedFarm.is_hide_finance,
    hasCycle: state => state.selectedFarm.info ? state.selectedFarm.info.cycle_count : false,
    hasStock: state => state.selectedFarm.info ? state.selectedFarm.info.stock_count : false,

    unit: state => state.warehouse.unit,

    getRoleById: (state) => (id) => {
      return state.roles.find(e => e.role_id === id) || {}
    },

    getAccess: (state) => (feature_name) => {
      return state.selectedFarm.permissions.find(e => e.feature_name === feature_name) || {action: -1}
    },
    canView: (state, getters) => (feature_name) => {
      return getters.getAccess(feature_name).action >= 0
    },
    canAdd: (state, getters) => (feature_name) => {
      return getters.getAccess(feature_name).action >= 1
    },
    canEdit: (state, getters) => (feature_name) => {
      return getters.getAccess(feature_name).action === 2
    },

    canViewPond: (state, getters) => {
      return getters.canView('pond')
    },
    canAddPond: (state, getters) => {
      return getters.canAdd('pond')
    },
    canEditPond: (state, getters) => {
      return getters.canEdit('pond')
    },
    canViewUser: (state, getters) => {
      return getters.canView('user_permission')
    },
    canAddUser: (state, getters) => {
      return getters.canAdd('user_permission')
    },
    canEditUser: (state, getters) => {
      return getters.canEdit('user_permission')
    },
    canViewCycle: (state, getters) => {
      return getters.canView('cycle')
    },
    canAddCycle: (state, getters) => {
      return getters.canAdd('cycle')
    },
    canEditCycle: (state, getters) => {
      return getters.canEdit('cycle')
    },
    canViewAnco: (state, getters) => {
      return getters.canView('cycle')
    },
    canAddAnco: (state, getters) => {
      return getters.canAdd('cycle')
    },
    canEditAnco: (state, getters) => {
      return getters.canEdit('cycle')
    },
    canViewSampling: (state, getters) => {
      return getters.canView('sampling')
    },
    canAddSampling: (state, getters) => {
      return getters.canAdd('sampling')
    },
    canEditSampling: (state, getters) => {
      return getters.canEdit('sampling')
    },
    canViewDeath: (state, getters) => {
      return getters.canView('death')
    },
    canAddDeath: (state, getters) => {
      return getters.canAdd('death')
    },
    canEditDeath: (state, getters) => {
      return getters.canEdit('death')
    },
    canViewDisease: (state, getters) => {
      return getters.canView('disease')
    },
    canAddDisease: (state, getters) => {
      return getters.canAdd('disease')
    },
    canEditDisease: (state, getters) => {
      return getters.canEdit('disease')
    },
    canViewStock: (state, getters) => {
      return getters.canView('stock')
    },
    canAddStock: (state, getters) => {
      return getters.canAdd('stock')
    },
    canEditStock: (state, getters) => {
      return getters.canEdit('stock')
    },
    canViewHarvest: (state, getters) => {
      return getters.canView('harverst')
    },
    canAddHarvest: (state, getters) => {
      return getters.canAdd('harverst')
    },
    canEditHarvest: (state, getters) => {
      return getters.canEdit('harverst')
    },
    canViewWarehouse: (state, getters) => {
      return getters.canView('warehouse')
    },
    canAddWarehouse: (state, getters) => {
      return getters.canAdd('warehouse')
    },
    canEditWarehouse: (state, getters) => {
      return getters.canEdit('warehouse')
    },
    canViewWaterQuality: (state, getters) => {
      return getters.canView('water_quality')
    },
    canAddWaterQuality: (state, getters) => {
      return getters.canAdd('water_quality')
    },
    canEditWaterQuality: (state, getters) => {
      return getters.canEdit('water_quality')
    },
    canViewWqs: (state, getters) => {
      return getters.canView('water_quality_setting')
    },
    canAddWqs: (state, getters) => {
      return getters.canAdd('water_quality_setting')
    },
    canEditWqs: (state, getters) => {
      return getters.canEdit('water_quality_setting')
    },
    canViewFinance: (state, getters) => {
      return getters.canView('finance')
    },
    canAddFinance: (state, getters) => {
      return getters.canAdd('finance')
    },
    canEditFinance: (state, getters) => {
      return getters.canEdit('finance')
    },

    canUseSidebar: (state, getters) => {
      return getters.canViewWaterQuality ||
        getters.canViewAnco ||
        getters.canAddSampling ||
        getters.canAddDisease ||
        getters.canAddDeath ||
        getters.canAddStock ||
        getters.canAddHarvest ||
        getters.canAddCycle
    },

    warehouseCategory: state => state.warehouse.categories,
  }
}

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    cost: localStorage.getItem('sgara.pgsql.cost') === 'true',
    wqs: localStorage.getItem('sgara.pgsql.wqs') === 'true',
    time: JSON.parse(localStorage.getItem('sgara.pgsql.time')),
  },
  mutations: {
    UPDATE_SETTING(state, payload = []) {
      for (let k in payload) {
        state[k] = payload[k]
        if (Array.isArray(state[k]) || typeof state[k] === 'object')
          localStorage.setItem('sgara.pgsql.' + k, JSON.stringify(state[k]))
        else
          localStorage.setItem('sgara.pgsql.' + k, state[k])
      }
    }
  },
  actions: {
    toggleCost({commit, state}) {
      commit('UPDATE_SETTING', {
        cost: !state.cost
      })
    },
    toggleWqs({commit, state}) {
      commit('UPDATE_SETTING', {
        wqs: !state.wqs
      })
    },

    addTime({commit, state}, payload = null) {
      if (payload) {
        let payload_time = Vue.prototype.$moment(payload).format('HH:mm')

        let time = state.time

        if (time.includes(payload_time)) return false

        time.push(payload_time)

        commit('UPDATE_SETTING', {
          time
        })
      }
    },

    removeTime({commit, state}, payload) {
      let time = state.time

      time.splice(payload, 1)

      commit('UPDATE_SETTING', {
        time
      })
    },

    setPresetTime(context, {item, time}) {
      let val = new Date(Vue.prototype.$dateFormat() + ' ' + time)
      Vue.prototype.$set(item, 'date', val)
    },

    updateLocale ({commit}, lang) {
      commit('UPDATE_SETTING', {
        lang
      })
    },
  },
}

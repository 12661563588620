import {initializeApp} from "firebase/app";
import {getMessaging, getToken, deleteToken} from "firebase/messaging";

import {CometChat} from "@cometchat-pro/chat"

export async function firebaseInitialize() {
  const firebaseConfig = {
    apiKey: "AIzaSyAMlRRMeF1bVlzwT2NrIztalXFTIVRGbyQ",
    authDomain: "sgara-39b3d.firebaseapp.com",
    projectId: "sgara-39b3d",
    storageBucket: "sgara-39b3d.appspot.com",
    messagingSenderId: "1053536617207",
    appId: "1:1053536617207:web:fca79449ed9a3b8215c9f2",
    measurementId: "G-8VH7NG71MM",
  }
  const app = await initializeApp(firebaseConfig);
  // const messaging = firebase.messaging()
  //
  // messaging.getToken({ vapidKey: 'vapidKey' }).then((currentToken) => {
  //   if (currentToken) {
  //     CometChat.registerTokenForPushNotification(currentToken).then((payload)=>{
  //       console.log("from comet",payload)
  //
  //     }).catch((err)=>{
  //       console.log(err)
  //     });
  //   } else {
  //     console.log('No registration token available. Request permission to generate one.');
  //   }
  // }).catch((err) => {
  //   console.log('An error occurred while retrieving token. ', err);
  // });
  //
  // messaging.onMessage(function(payload) {
  //   console.log('Receiving foreground message');
  //   console.log(payload)
  //   var notificationTitle = 'vue-cometchat-firebase';
  //   var notificationOptions = {
  //     body: payload.data.alert,
  //     icon: '',
  //   };
  //
  //   var notification = new Notification(notificationTitle, notificationOptions);
  //   notification.onclick = function(event) {
  //     notification.close();
  //     console.log(event);
  //   };
  // });
}

export async function firebaseMessage() {
  const messaging = getMessaging();
  getToken(messaging, {vapidKey: 'BFj9wDpQ_lab0i7hq6zGcbrZcUSpCd5TV-breX003KzUlZj15-NtN96y24OfQciwVMXGCjPEFw9GNB2Ddr5H6E0'}).then((currentToken) => {
    if (currentToken) {
      CometChat.registerTokenForPushNotification(currentToken).then(res => {
        console.log(res)
      }).catch((err) => {
        console.log(err)
      });
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    try {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("Permission granted!")
        }
        console.log(permission)
      })
    } catch (err) {
      console.log(err)
    }
  });
}

export async function firebaseLogout() {
  // Logout the user
  const messaging = getMessaging()
  await deleteToken(messaging)
  await CometChat.logout()
  console.log('logout')
}
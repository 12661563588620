import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import {
  CometChatConversationListWithMessages,
} from "../cometchat-pro-vue-ui-kit/CometChatWorkspace/src";
import SyncChatUser from "@/views/Sync/SyncChatUser";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import("@/views/Layout/Main"),
    children: [
      {
        path: '',
        component: () => import("@/views/Layout/ChatLayout"),
        children: [
          {
            path: '/chat',
            name: 'Chat',
            component: CometChatConversationListWithMessages,
            meta: {
              auth: true,
              hideChat: true,
              hideHelp: true,
              isGapless: true,
              hideNavbar: true,
            }
          },
        ]
      },
      {
        path: '/farm-registration',
        name: 'FarmRegistration',
        component: () => import("@/views/Farm/FarmRegistration"),
        meta: {
          auth: true,
          sidebar: false,
        }
      },
      {
        path: '/farm',
        name: 'FarmOverlay',
        component: () => import("@/views/Layout/FarmOverlay"),
        meta: {
          auth: true
        },
        children: [
          {
            path: ':farm_id',
            component: () => import("@/components/Farm/Overlay"),
            children: [
              {
                path: '',
                component: () => import("@/views/MainMenu"),
                meta: {
                  auth: true,
                  overlay: true,
                },
              },
              {
                path: 'finance',
                component: () => import("@/views/Layout/FinanceOverlay"),
                children: [
                  {
                    path: '',
                    name: 'Finance',
                    component: () => import("@/views/Farm/Finance/FinanceIndex"),
                    meta: {
                      auth: true,
                    },
                  },
                  {
                    path: 'settings',
                    name: 'FinanceSettings',
                    component: () => import("@/views/Farm/Finance/FinanceSetting"),
                    meta: {
                      auth: true,
                      sidebar: false,
                      backUrl: '/finance'
                    },
                  },
                  {
                    path: 'report',
                    name: 'FinanceReport',
                    component: () => import("@/views/Farm/Finance/FinanceReport"),
                    meta: {
                      auth: true,
                      sidebar: false,
                      navbar: false,
                      embed: true,
                    },
                  },
                ]
              },
              {
                path: 'overview',
                name: 'CycleOverview',
                component: () => import("@/views/Farm/Cycle/CycleOverview"),
                meta: {
                  auth: true
                },
              },
              {
                path: 'overview/embed',
                name: 'CycleOverviewEmbed',
                component: () => import("@/views/Farm/Cycle/CycleOverviewEmbed"),
                meta: {
                  auth: true,
                  embed: true,
                  navbar: false,
                },
              },
              {
                path: 'history',
                name: 'ReportHistory',
                component: () => import("@/views/Farm/ReportHistory/ReportHistory"),
                meta: {
                  auth: true,
                  backUrl: '/',
                },
              },
              {
                path: 'history/embed',
                name: 'ReportHistoryEmbed',
                component: () => import("@/views/Farm/ReportHistory/ReportHistoryEmbed"),
                meta: {
                  auth: true,
                  embed: true,
                  navbar: false,
                },
              },
              {
                path: 'warehouse',
                name: 'Warehouse',
                component: () => import("@/views/Farm/Warehouse/Warehouse"),
                meta: {
                  auth: true,
                  backUrl: '/',
                },
              },
              {
                path: 'warehouse/:warehouse_id',
                name: 'WarehouseDetail',
                component: () => import("@/views/Farm/Warehouse/WarehouseDetail"),
                meta: {
                  auth: true,
                  sidebar: false,
                  backUrl: '/warehouse'
                },
              },
              {
                path: 'settings',
                name: 'Setting',
                component: () => import("@/views/Farm/Setting/Setting"),
                meta: {
                  auth: true,
                  sidebar: true,
                  backUrl: '/'
                },
              },
              {
                path: 'comparison',
                name: 'Comparison',
                component: () => import("@/views/Farm/Comparison/Comparison"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                  backUrl: '/',
                },
              },
              // {
              //   path: 'daily',
              //   name: 'Daily',
              //   component: () => import("@/views/Farm/Daily/Daily"),
              //   meta: {
              //     auth: true,
              //     sidebar: true,
              //     overlay_nav: true,
              //     embed: true,
              //   },
              // },
              // {
              //   path: 'notes',
              //   name: 'Notes',
              //   component: () => import("@/views/Farm/Note/Notes"),
              //   meta: {
              //     auth: true,
              //     sidebar: false,
              //   },
              // },
              // {
              //   path: 'import',
              //   name: 'Import',
              //   component: () => import("@/views/Farm/Import/Import"),
              //   meta: {
              //     auth: true,
              //     sidebar: false,
              //   },
              // },


              {
                path: 'water-change',
                name: 'InputWaterChange',
                component: () => import("@/views/Farm/WaterChange/InputWaterChange"),
                meta: {
                  auth: true,
                  sidebar: false,
                  backUrl: '/',
                },
              },
              // {
              //   path: 'water-quality',
              //   name: 'InputWaterQuality',
              //   component: () => import("@/views/Farm/WaterQuality/InputWaterQuality"),
              //   meta: {
              //     auth: true,
              //     sidebar: false,
              //   },
              // },
              {
                path: 'water-quality/add',
                name: 'InputWaterQualityForm',
                component: () => import("@/views/Farm/WaterQuality/InputWaterQualityForm"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                  backUrl: '/',
                },
              },
              /*
              {
                path: 'use-item',
                name: 'InputUseItem',
                component: () => import("@/views/Farm/UseItem/InputUseItem"),
                meta: {
                  auth: true,
                  sidebar: false,
                },
              },
              {
                path: 'use-item/add',
                name: 'InputUseItemForm',
                component: () => import("@/views/Farm/UseItem/InputUseItemForm"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                },
              },
               */
              {
                path: 'feed/add',
                name: 'InputFeedForm',
                component: () => import("@/views/Farm/UseItem/InputFeedForm"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                  backUrl: '/',
                },
              },
              {
                path: 'use-item/add',
                name: 'InputProbioticsForm',
                component: () => import("@/views/Farm/UseItem/InputProbioticsForm"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                  backUrl: '/',
                },
              },
              // {
              //   path: 'feed-tray',
              //   name: 'InputFeedTray',
              //   component: () => import("@/views/Farm/FeedTray/InputFeedTray"),
              //   meta: {
              //     auth: true,
              //     sidebar: false,
              //     backUrl: '/',
              //   },
              // },
              {
                path: 'feed-tray/add',
                name: 'InputFeedTrayForm',
                component: () => import("@/views/Farm/FeedTray/InputFeedTrayForm"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                  backUrl: '/',
                },
              },
              {
                path: 'sampling/add',
                name: 'InputSamplingForm',
                component: () => import("@/views/Farm/Sampling/InputSamplingForm"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                  backUrl: '/',
                },
              },


              {
                path: 'purchase-order',
                name: 'InputPurchaseOrder',
                component: () => import("@/views/Farm/PurchaseOrder/InputPurchaseOrder"),
                meta: {
                  auth: true,
                  sidebar: false,
                  embed: true,
                  backUrl: '/',
                },
              },

              //   Report
              {
                path: 'report',
                name: 'Report',
                component: () => import("@/views/Farm/Report"),
                meta: {
                  auth: true,
                  sidebar: false,
                  navbar: false,
                  embed: true,
                },
              },
            ]
          },
        ]
      },
      {
        path: '/notifications',
        name: 'Notification',
        component: () => import("@/views/User/Notification"),
        meta: {
          auth: true,
          sidebar: false
        },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import("@/views/User/Profile"),
        meta: {
          auth: true,
          sidebar: false
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Auth/Login"),
    meta: {
      hideChat: true,
      auth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import("@/views/Auth/ForgotPassword"),
    meta: {
      hideChat: true,
      auth: false
    }
  },
  {
    path: '/reset/password',
    name: 'ResetPassword',
    component: () => import("@/views/Auth/ResetPassword"),
    meta: {
      hideChat: true,
      auth: false
    }
  },
  {
    path: '/email-verification',
    name: 'EmailVerification',
    component: () => import("@/views/Auth/EmailVerification"),
    meta: {
      hideChat: true,
      auth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/Auth/Register"),
    meta: {
      hideChat: true,
      auth: false
    }
  },
  {
    path: '/sync-chat-user',
    name: 'SyncChatUser',
    component: SyncChatUser,
    meta: {
      auth: true,
      hideChat: true,
    }
  },
  {
    path: '*',
    redirect: '/',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {
  if (to.query.output === 'embed' && to.query.user && to.meta.embed) {
    store.state.user.user = {
      token: to.query.user,
      user: {
        pu_id: to.query.pu_id,
      }
    }
    document.querySelector('body').classList.add('embed')
  }

  const user = store.getters["user/loggedIn"]

  if (to.meta.auth && !user) return next('/login')
  else if (to.meta.auth === false && user) return next('/farm')
  else if (to.path === '/') return next('/farm')

  let sidebar = true

  if (to.meta.sidebar === false) sidebar = false

  store.dispatch('sidebar', sidebar)

  store.state.farm.selectedFarm.farm_id = parseInt(to.params.farm_id)

  setTimeout(() => {
    store.commit('UPDATE_NAVBAR', false)
  }, 0)

  next()
})

router.afterEach(() => {
  setTimeout(() => {
    store.commit('UPDATE_NAVBAR')
  }, 0)
})

export default router

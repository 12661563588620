import axios from 'axios'
import store from '../store/store'
import {NotificationProgrammatic as Notification} from 'buefy'

import qs from 'qs'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_HATCHERY_URL + '/api/v1'
})

const getConfig = () => {
  return {
    headers: {Authorization: `Bearer ${store.getters["user/token"]}`}
  }
}

api.interceptors.request.use((config) => {
  return config
})

api.interceptors.response.use(response => {
  let message = response.data.message

  if (message && (message === 'bad request' || message.toLowerCase().includes('tidak ditemukan') || message.toLowerCase().includes('found')|| message.toLowerCase().includes('role data'))) {
    return Promise.reject(response)
  }

  return Promise.resolve(response)
}, (error) => {
  let message = ''

  if (error && error.response && error.response.data && error.response.data.message && typeof error.response.data.message === 'string') {
    message = error.response.data.message
  } else if (error.response.statusText) {
    message = error.response.statusText
  }

  if (message) {
    Notification.open({
      message: message,
      type: 'is-danger',
    })
  }

  // if (messsage) store.dispatch('updateErrorMessage', messsage)
  //
  // if (error.response.status === 401) store.dispatch('auth/logout')

  return Promise.reject(error.response)
})

const get = async (url, data) => {
  let result = {
    data: {},
    message: '',
    error: false,
    status: null
  }

  let config = getConfig()
  config.params = data

  await api.get(url, config).then(res => {
    result.data = res.data
    result.message = res.message
  }).catch(err => {
    if (err.response && err.response.data) {
      result.data = err.response.data
      result.message = err.response.message
    }
    result.error = true
  })

  return result
}

const post = async (url, data, stringfied = true) => {
  let result = {
    data: {},
    message: '',
    error: false
  }

  if (stringfied) data = await qs.stringify(data)
  await api.post(url, data, getConfig()).then(res => {
    result.data = res.data
    result.message = res.message
  }).catch(err => {
    if (err.response && err.response.data) {
      result.data = err.response.data
      result.message = err.response.message
    }
    result.error = true
  })

  return result
}

const put = async (url, data, stringfied = true) => {
  let result = {
    data: {},
    message: '',
    error: false
  }

  if (stringfied) data = await qs.stringify(data)
  await api.put(url, data, getConfig()).then(res => {
    result.data = res.data
    result.message = res.message
  }).catch(err => {
    if (err.response && err.response.data) {
      result.data = err.response.data
      result.message = err.response.message
    }
    result.error = true
  })

  return result
}

const patch = async (url, data, stringfied = true) => {
  let result = {
    data: {},
    message: '',
    error: false
  }

  if (stringfied) data = await qs.stringify(data)
  await api.patch(url, data, getConfig()).then(res => {
    result.data = res.data
    result.message = res.message
  }).catch(err => {
    if (err.response && err.response.data) {
      result.data = err.response.data
      result.message = err.response.message
    }
    result.error = true
  })

  return result
}

const destroy = async (url) => {
  let result = {
    data: {},
    message: '',
    error: false
  }

  await api.delete(url, getConfig()).then(res => {
    result.data = res.data
    result.message = res.message
  }).catch(err => {
    if (err.response && err.response.data) {
      result.data = err.response.data
      result.message = err.response.message
    }
    result.error = true
  })

  return result
}

export default {
  get,
  post,
  put,
  patch,
  destroy,
}
